import { consoleLog } from "@helper/logs";
import { ACCOUNTS } from "../../constants/accounts";
import { capitalizeFirstLetter } from "@helper/string";
import { getFinancialYear, isPastInvoice, isFutureFYear, convertMonthToIndex, getYear } from "@helper/time";

// used from tally master.xml
export const inventoryData = async ({ expenses, inventory, fYear = '2022-23', months = [...Array(12)].map((e, i) => i < 9 ? i + 3 : i - 9), asinData, hsnData }) => {
  const applicableFrom = `${fYear.slice(0, 4)}0401`;

  const promises = inventory.map(async (product) => {
    const stockData = await productStockData({ expenses, product, fYear, months, asinData, noFloor: true });

    return {
      OPENINGBALANCE: stockData.quantity,
      OPENINGVALUE: stockData.value,
      NAME: product.name,
      SKU: product.sku,
      APPLICABLEFROM: applicableFrom,
      HSNCODE: product.hsnCode,
      TAXABILITY: capitalizeFirstLetter(product.taxPreference),
      uqc: hsnData[product.hsnCode] ? hsnData[product.hsnCode].uqc : 'PCS',
      TAX: product.tax
    }
  });

  return Promise.all(promises);
}

export const productStockData = async ({ expenses, product, fYear = '2022-23', months = [...Array(12)].map((e, i) => i < 9 ? i + 3 : i - 9), asinData, noFloor }) => {
  // get months from April to the max of selected month
  const maxMonth = Math.max(...months);
  const minMonth = Math.min(...months);
  // const applicableMonthsCeil = !minMonth && maxMonth === 11 ? 'entireYear' : maxMonth < 3 ? 'betweenJanuaryToMarch' : 'betweenAprilToDecember';
  const applicableMonthsCeil = !minMonth && maxMonth === 11 ? 2 : maxMonth;
  const applicableMonthsFloor = (noFloor) ? 'noFloor' : !minMonth && maxMonth === 11 ? 3 : minMonth;

  const ceilingData = await fullProductStockData({ expenses, product, fYear, applicableMonth: applicableMonthsCeil, asinData });
  consoleLog('Selected months: ', months);
  consoleLog('Ceiling Months: ', applicableMonthsCeil);
  consoleLog('Ceiling Data: ', ceilingData);
  if (applicableMonthsFloor === 'noFloor') {
    return ceilingData;
  }

  const floorData = await fullProductStockData({ expenses, product, fYear: applicableMonthsFloor === 3 ? getFinancialYear(getYear(fYear, 3), 2) : fYear, applicableMonth: applicableMonthsFloor ? applicableMonthsFloor - 1 : 11, asinData });

  consoleLog('Floor Data: ', floorData);
  consoleLog({
    pendingQty: ceilingData.pendingQty,
    quantity: ceilingData.quantity - floorData.quantity,
    value: (+ceilingData.value - +floorData.value).toFixed(2),
    totalPurchasedData: ceilingData.totalPurchasedData,
    // alternatively, it can be directly fetched from ASIN
    totalSoldData: {
      q: ceilingData.totalSoldData.q - floorData.totalSoldData.q,
      v: ceilingData.totalSoldData.v - floorData.totalSoldData.v,
    },
    soldStockValue: ceilingData.soldStockValue - floorData.soldStockValue,
    isSalableProduct: ceilingData.isSalableProduct
  });


  return {
    pendingQty: ceilingData.pendingQty,
    quantity: ceilingData.quantity - floorData.quantity,
    value: (+ceilingData.value - +floorData.value).toFixed(2),
    totalPurchasedData: ceilingData.totalPurchasedData,
    // alternatively, it can be directly fetched from ASIN
    totalSoldData: {
      q: ceilingData.totalSoldData.q - floorData.totalSoldData.q,
      v: ceilingData.totalSoldData.v - floorData.totalSoldData.v,
    },
    soldStockValue: ceilingData.soldStockValue - floorData.soldStockValue,
    isSalableProduct: ceilingData.isSalableProduct
  }
}

const getAsinDataUptoApplicableMonth = async ({ fYear, applicableMonth, asinData }) => {
  return Object.entries(asinData)
    .filter(([asinFYear, data]) => !isFutureFYear(asinFYear, fYear))
    .reduce((prevData, [asinFYear, data]) => {
      if (asinFYear !== fYear) {
        return prevData.concat(Object.values(data))
      } else {
        const filteredData = Object.entries(data)
          .filter(([month, subData]) => convertMonthToIndex(applicableMonth) >= convertMonthToIndex(month))
          .map(([month, subData]) => subData);
        return prevData.concat(filteredData)
      }
    }, [])

}

const fullProductStockData = async ({ expenses, product, fYear = '2022-23', applicableMonth, asinData }) => {
  const asinSell = await getAsinDataUptoApplicableMonth({ fYear, applicableMonth, asinData });
  const isSalableProduct = Object.values(ACCOUNTS["Cost of Goods Sold"]).includes(product.account);
  const resetQtyToZeroIfNegative = product.account !== ACCOUNTS["Cost of Goods Sold"]["Cost of Goods sold"];

  // first we calculate quantity and value of each stock upto **applicableMonth**.
  const totalPurchasedData = expenses?.reduce((prev, curr) => {
    if (isPastInvoice(curr.invoiceDate, { fYear, month: applicableMonth })) {
      const prodData = curr.items.find(e => e.item === product.name);

      if (prodData) {
        const prodExpense = curr.import ? +prodData.unitValue * +prodData.quantity :
          +prodData.tax ? +prodData.expenseAmount * 100 / (100 + +prodData.tax) : +prodData.expenseAmount;

        return { q: +prodData.quantity + prev.q, v: prodExpense + prev.v }
      };
    }

    return prev;
  }, { q: +product.openingStockQuantity, v: +product.openingStockValue });


  // secondly we calculate total sold quantity of upto **applicableMonth**.
  const totalSoldData = isSalableProduct && asinSell ? asinSell
    .reduce((prev, curr) => {
      const currData = { ...prev };
      product.associationASIN.forEach(asin => {
        if (asin && asin.id) {
          if (curr?.b2b && curr.b2b?.asinQty) {
            if (curr.b2b.asinQty[asin.id]) currData.q += (curr.b2b.asinQty[asin.id] * +asin.qty);
            if (curr.b2b.asinSell[asin.id]) currData.v += curr.b2b.asinSell[asin.id];
            if (curr.b2b.asinTcs[asin.id]) currData.t += curr.b2b.asinTcs[asin.id];
          }

          if (curr?.b2c && curr.b2c?.asinQty) {
            if (curr.b2c.asinQty[asin.id]) currData.q += (curr.b2c.asinQty[asin.id] * +asin.qty);
            if (curr.b2c.asinSell[asin.id]) currData.v += curr.b2c.asinSell[asin.id];
            if (curr.b2c.asinTcs[asin.id]) currData.t += curr.b2c.asinTcs[asin.id];
          }
        }
      });

      return currData;
    }, { q: 0, v: 0, t: 0 }) : { q: 0, v: 0, t: 0 };

  // thirdly and lastly, we calculate total sold value of pending qty 
  let pendingQty = totalSoldData.q;
  let soldStockValue = 0;

  if (+product.openingStockQuantity) {
    if (pendingQty > +product.openingStockQuantity) {
      soldStockValue += +product.openingStockValue;
      pendingQty -= +product.openingStockQuantity;
    } else {
      soldStockValue += +product.openingStockValue * pendingQty / +product.openingStockQuantity;
      pendingQty = 0;
    }

  }

  expenses.forEach(expense => {
    if (pendingQty > 0) {
      if (isPastInvoice(expense.invoiceDate, { fYear, month: applicableMonth })) {
        const prodData = expense.items.find(e => e.item === product.name);

        if (prodData) {

          if (expense.import) {
            if (pendingQty > +prodData.quantity) {
              soldStockValue += +prodData.unitValue * +prodData.quantity;
              pendingQty -= +prodData.quantity;
            } else {
              soldStockValue += +prodData.unitValue * pendingQty;
              pendingQty = 0;
            }

          } else {
            const prodExpense = +prodData.tax ? +prodData.expenseAmount * 100 / (100 + +prodData.tax) : +prodData.expenseAmount;

            if (pendingQty > +prodData.quantity) {
              soldStockValue += prodExpense;
              pendingQty -= +prodData.quantity;
            } else {
              soldStockValue += prodExpense * pendingQty / +prodData.quantity;
              pendingQty = 0;
            }
          }
        }
      }
    }
  });

  if (pendingQty) {
    if (resetQtyToZeroIfNegative) {
      pendingQty = 0;
    }

    console.error(`In ${product?.name}, the total number of sold items(${totalSoldData?.q}) is greater than the total purchased items(${totalPurchasedData?.q}). Please update your inventory!`)
  }

  const isQtyNegative = totalSoldData?.q > totalPurchasedData?.q;
  const quantity = isQtyNegative && resetQtyToZeroIfNegative ? 0 : totalPurchasedData?.q - totalSoldData?.q;
  const value = isQtyNegative && resetQtyToZeroIfNegative ? 0 : (totalPurchasedData?.v - soldStockValue).toFixed(2);

  return {
    pendingQty,
    quantity,
    value,
    totalPurchasedData,
    totalSoldData,
    soldStockValue,
    isSalableProduct
  }
}

export const saleData = async ({ fYear = '2022-23', months = [], getAsinData }) => {
  const asinSell = await getAsinData({ fYear, months });


  const tcsData = asinSell ? Object.values(asinSell).reduce((prev, curr) => {
    let total = prev;

    if (curr.b2b && curr.b2b.asinSell) {
      total.tcs += Object.values(curr.b2b.asinTcs).reduce((p, c) => p + c, 0);
      total.sale += Object.values(curr.b2b.asinSell).reduce((p, c) => p + c, 0);
    }

    if (curr.b2c && curr.b2c.asinSell) {
      total.tcs += Object.values(curr.b2c.asinTcs).reduce((p, c) => p + c, 0);
      total.sale += Object.values(curr.b2c.asinSell).reduce((p, c) => p + c, 0);
    }

    return total;
  }, { tcs: 0, sale: 0 }) : { tcs: 0, sale: 0 };

  return tcsData
}