import moment from "moment/moment";
import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import { ACCOUNTS } from "../constants/accounts";
import { consoleLog, consoleWarn } from "@helper/logs";
import {
  inventoryData,
  productStockData,
  saleData,
} from "../reducer/stock/productStock";
import { ApiContext } from "./api";
import { convertDateFromCsv } from "@helper/time";
import { getName } from "country-list";

import {
  initialState,
  purchaseReducer,
  PurchaseReducerActionType,
} from "./reducers/purchaseReducer";
import { addAlertContext } from "./popup";
import { isIgst } from "@helper/tax";
import { getFinancialYear } from "@helper/time";
import { validStatesAbbreviation } from "../constants/states";
import { expenseAmount } from "@helper/expense";
import { capitalizeFirstLetter } from "@helper/string";
import { useSearchParams } from "react-router-dom";

const PurchaseContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  consoleLog("Purchase provider renders");
  // main purchase reducer
  const [state, dispatch] = useReducer(purchaseReducer, initialState);
  const { user, sales, purchase } = useContext(ApiContext);
  const addAlert = useContext(addAlertContext);
  const [firstTime, setFirstTime] = useState(true);
  const [searchParams] = useSearchParams();
  const [asinData, setAsinData] = useState([]);

  useEffect(() => {
    sales.getAsinData({ fYear: undefined }).then(data => {
      setAsinData(data)
    })
  }, [])

  useEffect(() => {
    if (user.currentUser && firstTime) {
      setFirstTime(false);
      purchase.getPurchaseData("inventory").then((data) => {
        dispatch({
          type: PurchaseReducerActionType.UPDATE_PRODUCT,
          payload: {
            inventory: data || [],
          },
        });
      });
      purchase.getPurchaseData("vendors").then((data) => {
        dispatch({
          type: PurchaseReducerActionType.UPDATE_VENDOR,
          payload: {
            vendors: data || [],
          },
        });
      });
      purchase.getPurchaseData("expenses").then((data) => {
        dispatch({
          type: PurchaseReducerActionType.UPDATE_EXPENSE,
          payload: {
            expenses: data || [],
          },
        });
      });
    }
  }, [user.currentUser]);

  const addProduct = (product) => {
    const updatedInventory = state.inventory.concat(product);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_PRODUCT,
      payload: {
        inventory: updatedInventory,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const removeProduct = (index) => {
    if (
      state.expenses.some((d) =>
        d.items.some((e) => e.item === state.inventory[index].name)
      )
    ) {
      addAlert(
        "You can't delete this item as it's already in use inside one or more Invoices!!"
      );
      return;
    }

    const updatedInventory = state.inventory.filter((_, i) => i !== index);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_PRODUCT,
      payload: {
        inventory: updatedInventory,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const updateProduct = (product, index) => {
    var updatedInventory = state.inventory.slice();
    updatedInventory.splice(index, 1, product);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_PRODUCT,
      payload: {
        inventory: updatedInventory,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const addVendor = (vendor) => {
    const updatedVendors = state.vendors.concat(vendor);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_VENDOR,
      payload: {
        vendors: updatedVendors,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const removeVendor = (index) => {
    if (
      state.expenses.some(
        (d) => d.vendor === state.vendors[index].primaryContact?.companyName
      )
    ) {
      addAlert(
        "You can't delete this vendor as it's already in use inside one or more Invoices!!"
      );
      return;
    }
    const updatedVendors = state.vendors.filter((_, i) => i !== index);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_VENDOR,
      payload: {
        vendors: updatedVendors,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const updateVendor = (vendor, index) => {
    var updatedVendors = state.vendors.slice();
    updatedVendors.splice(index, 1, vendor);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_VENDOR,
      payload: {
        vendors: updatedVendors,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const addExpense = (expense) => {
    const updatedExpenses = state.expenses.concat(expense);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_EXPENSE,
      payload: {
        expenses: updatedExpenses,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const removeExpense = (index) => {
    const updatedExpenses = state.expenses.filter((_, i) => i !== index);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_EXPENSE,
      payload: {
        expenses: updatedExpenses,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const updateExpense = (expense, index) => {
    var updatedExpenses = state.expenses.slice();
    updatedExpenses.splice(index, 1, expense);

    dispatch({
      type: PurchaseReducerActionType.UPDATE_EXPENSE,
      payload: {
        expenses: updatedExpenses,
        storePurchaseData: purchase.storePurchaseData,
      },
    });
  };

  const currentStockData = ({ product }) => {
    return productStockData({
      expenses: state.expenses,
      product,
      asinData,
      noFloor: true
    });
  };

  const incomeStatement = async ({ fYear, months }) => {
    const asinSell = await sales.getAsinData({ fYear, months });
    if (!asinSell) return null;

    const missingAsin = [
      ...new Set(
        Object.values(asinSell).reduce((prev, curr) => {
          Object.values(curr).forEach((element) => {
            if (element.asinQty) {
              prev = prev.concat(Object.keys(element.asinQty));
            }
          });

          return prev;
        }, [])
      ),
    ];

    const missingSaleData = [];
    months.forEach((month) => {
      if (!asinSell[month] || !asinSell[month].b2b || !asinSell[month].b2c) {
        missingSaleData.push(month);
        consoleWarn(
          `ASIN data for month ${moment(+month + 1, "MM").format(
            "MMMM"
          )} is missing`
        );
      }
    });

    const financialData = await state.inventory.reduce(
      async (prevData, product) => {
        const prevOutput = await prevData;

        if (
          Object.keys(ACCOUNTS["Cost of Goods Sold"]).includes(product.account)
        ) {
          prevOutput.missingAsin = prevOutput.missingAsin?.filter(
            (id) => !product.associationASIN.find((asin) => asin.id === id)
          );

          const currentStockData = await productStockData({
            expenses: state.expenses,
            product,
            fYear,
            months,
            asinData,
          });

          if (currentStockData.pendingQty > 0) {
            console.error(
              `Ooops!! Your total sold quantity of the items ${product.name} is greater than the available quantity. Kindly correct your Inventory and/or expenses to view the Income Statement.`
            );
            prevOutput.inCompleteProducts.push(product.name);
          } else {
            prevOutput.costOfGoods += currentStockData.soldStockValue;
          }
        } else {
          prevOutput.otherCosts += state.expenses.reduce((prev, curr) => {
            if (months.includes(moment(curr.invoiceDate).month())) {
              const prodData = curr.items.find((e) => e.item === product.name);

              if (prodData) {
                let taxValue =
                  prodData.tax === "non-taxable" ? 0 : prodData.tax;
                const prodExpense =
                  (+prodData.expenseAmount * 100) / (100 + +taxValue);

                return prodExpense + prev;
              }
            }

            return prev;
          }, 0);
        }

        return Promise.resolve(prevOutput);
      },
      Promise.resolve({
        costOfGoods: 0,
        netSale: 0,
        otherCosts: 0,
        inCompleteProducts: [],
        missingAsin,
      })
    );

    const sData = await saleData({
      fYear,
      months,
      getAsinData: sales.getAsinData,
    });
    // get sData of every month.
    financialData.netSale = sData.sale;
    financialData.taxExpense = sData.tcs;

    return { financialData, missingSaleData };
  };

  const tallyObject = async ({ fYear = "2022-23", months }) => {
    const hsnData = await sales.getHsnData();

    const saleData = await months.reduce(async (prev, currMonth) => {
      const prevOutput = await prev;
      const { b2b, b2c } = await sales.getFile(currMonth, fYear);
      const mtrData = b2b && b2c ? b2b.concat(b2c) : b2b ?? b2c;

      if (mtrData) {
        const mappedSaleData = mtrData
          .map((data) => {
            const isCreditNote = data["Credit Note No"].length > 0;
            const gstTax = +data["Igst Tax"]
              ? +data["Igst Tax"]
              : +data["Sgst Tax"] + +data["Cgst Tax"];
            const PARTYNAME = data["Buyer Name"] || (isCreditNote
              ? "B2C Returns"
              : "B2C Sales")

            return {
              DATE: isCreditNote
                ? convertDateFromCsv(data["Credit Note Date"]).format(
                    "DD/MM/YYYY"
                  )
                : convertDateFromCsv(data["Invoice Date"]).format("DD/MM/YYYY"),
              REFERENCE: isCreditNote
                ? data["Credit Note No"]
                : data["Invoice Number"],
              PARTYNAME,
              _VCHTYPE: isCreditNote ? "Credit Note" : "Sales",
              AMOUNT: Math.abs(+data["Invoice Amount"]),
              COUNTRYOFRESIDENCE: data["Bill To Country"]
                ? getName(data["Bill To Country"])
                : "India",
              STATE: data["Bill To State"]
                ? validStatesAbbreviation[data["Bill To State"]]?.slice(3)
                : "",
              STOCKITEMNAME: data["Item Description"],
              isIGST: isIgst(data),
              qty: +data["Quantity"],
              uqc: hsnData[data["Hsn/sac"]]
                ? hsnData[data["Hsn/sac"]].uqc
                : "PCS",
              gstTax: gstTax,
              gstId: data["Customer Bill To Gstid"] ?? "",
            };
          })
          .filter((data) => data.AMOUNT !== 0);
        prevOutput.saleData = prevOutput.saleData.concat(mappedSaleData);
      } else {
        prevOutput.missingMonths = prevOutput.missingMonths.concat(currMonth);
      }

      return prevOutput;
    }, Promise.resolve({ missingMonths: [], saleData: [] }));

    const minMonth = Math.min(...months);
    const maxMonth = Math.max(...months);

    let currentStockData;
    const year = searchParams.get("ty").split('-')?.[0];

    // currentStock == initialInventory
    if (minMonth === 3 || (minMonth === 0 && maxMonth === 11)) {
      currentStockData = state.inventory.map((product) => {
        return {
          OPENINGBALANCE: product.openingStockQuantity,
          OPENINGVALUE: product.openingStockValue,
          NAME: product.name,
          SKU: product.sku,
          APPLICABLEFROM: year + "0401",
          HSNCODE: product.hsnCode,
          TAXABILITY: capitalizeFirstLetter(product.taxPreference),
          TAX: product.tax,
          uqc: hsnData[product.hsnCode] ? hsnData[product.hsnCode].uqc : "PCS",
        };
      });
    } else {
      const prevMonth = minMonth && minMonth !== 3 ? minMonth - 1 : 11;

      currentStockData = await inventoryData({
        expenses: state.expenses,
        inventory: state.inventory,
        fYear,
        months: [prevMonth],
        asinData,
        hsnData,
      });
    }

    const purchaseData = state.expenses
      .filter((expense) => {
        const date = new Date(expense.invoiceDate);
        const year = date.getFullYear();
        const month = date.getMonth();

        return (
          months.includes(month) && getFinancialYear(year, month) === fYear
        );
      })
      .map((expense, i) => {
        const tax = expense?.items.reduce((prev, curr) => {
          const taxRate = isNaN(curr.tax) ? 0 : +curr.tax;
          return prev + (curr.expenseAmount * taxRate) / (100 + taxRate);
        }, 0);

        const vendorData = state.vendors.find(vendor => vendor?.primaryContact?.companyName === expense?.vendor)

        return {
          DATE: moment(expense.invoiceDate).format("DD/MM/YYYY"),
          REFERENCE: expense.invoiceNumber,
          PARTYNAME: expense.vendor,
          _VCHTYPE: "Purchase",
          AMOUNT: expenseAmount(expense),
          OPENINGBALANCE: 0,
          OPENINGVALUE: 0,
          COUNTRYOFRESIDENCE: expense.countryOfOrigin ?? "India",
          STATE: expense.sourceOfSupply
            ? validStatesAbbreviation[expense.sourceOfSupply]?.slice(3)
            : "",
          STOCKITEMNAME: expense.items[0]?.item ?? "",
          isIGST: true,
          qty: expense?.items.reduce((prev, curr) => {
            const currQuantity = isNaN(curr.quantity) ? 0 : +curr.quantity;
            return prev + currQuantity;
          }, 0),
          uqc: "PCS",
          gstTax: Math.round(tax * 100) / 100,
          gstId: vendorData?.gstINOrUIN
        };
      });

    return { ...saleData, purchaseData, currentStockData };
  };

  const value = {
    inventory: state.inventory,
    addProduct,
    removeProduct,
    updateProduct,

    vendors: state.vendors,
    addVendor,
    removeVendor,
    updateVendor,

    expenses: state.expenses,
    addExpense,
    removeExpense,
    updateExpense,
    currentStockData,
    incomeStatement,

    tallyObject,
  };

  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  );
};

const usePurchase = () => {
  const context = useContext(PurchaseContext);

  if (context === undefined) {
    throw new Error("usePurchase must be used within PurchasePages");
  }

  return context;
};

export default usePurchase;
