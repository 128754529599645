import { currentYearMonth } from "./time"

export const getSaleParam = () => {
  return getData('sp')
}

export const storeSaleParam = (data) => {
  setData('sp', data)
}

export const getFinanceParam = (prefix) => {
  return getData(`${prefix}p`)
}

export const storeFinanceParam = (data, prefix) => {
  setData(`${prefix}p`, data)
}

const getData = (key) => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null
};

const setData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const clearStorage = () => {
  localStorage.removeItem('sp');
  localStorage.removeItem('fp');
}

export const initStorage = () => {
  const { currentFYear, currentYear, currentMonth } = currentYearMonth();

  if (currentMonth) {
    storeSaleParam({ y: currentYear, m: currentMonth - 1 });
    storeFinanceParam({ fy: currentFYear, fm: currentMonth - 1 }, 'f');
    storeFinanceParam({ ty: currentFYear, tm: currentMonth - 1 }, 't');
  } else {
    storeFinanceParam({ fy: currentFYear, fm: 11 }, 'f');
    storeFinanceParam({ ty: currentFYear, tm: 11 }, 't');
    storeSaleParam({ y: currentYear - 1, m: 11 });
  }
}