export const config = {
  firebase: {
    apiKey: "AIzaSyCKc4Ag6eiCAOiStkTCpcUVqfezE2C64uw",
    authDomain: "fliqbook-in.firebaseapp.com",
    projectId: "fliqbook-in",
    storageBucket: "fliqbook-in.appspot.com",
    messagingSenderId: "550327586271",
    appId: "1:550327586271:web:47ae785ab9527f947ec86a",
    measurementId: "G-9QDQB0045Z"
  }
}