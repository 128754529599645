import {
  currentYearMonth,
  isSameMonth,
  isSameMonthRange,
  isSameYear,
  isSameYearRange,
} from "./time";

export const isValidFile = (data, type, month, year, gstId, numberOfAsin) => {
  if (!data.length) {
    throw new Error("This file is corrupted!");
  } else if (numberOfAsin > 250) {
    throw new Error("File contains more than 250 ASINs.!");
  } else if (type === "b2b" && !data[0]["Customer Bill To Gstid"]) {
    throw new Error("Not a B2B MTR!");
  } else if (type === "b2c" && data[0]["Customer Bill To Gstid"]) {
    find;
    throw new Error("Not a B2C MTR!");
  } else if (data.some((d) => d["Seller Gstin"] !== gstId)) {
    throw new Error(`GSTN in the file and profile does not match!`);
  } else if (
    isNonEmpty(data[0]["Credit Note Date"])
      ? !isSameYear(data[0]["Credit Note Date"], year)
      : !isSameYear(data[0]["Invoice Date"], year)
  ) {
    throw new Error(`File doesn't belong to selected year!`);
  } else if (
    isNonEmpty(data[0]["Credit Note Date"])
      ? !isSameMonth(data[0]["Credit Note Date"], month)
      : !isSameMonth(data[0]["Invoice Date"], month)
  ) {
    throw new Error(`File doesn't belong to selected month!`);
  }

  return true;
};

export const isValidXml = (
  data,
  startDate,
  endDate,
  currentMonth,
  currentYear
) => {
  if (!isSameYearRange(startDate, endDate, currentMonth)) {
    return `File doesn't belong to selected year!`;
  } else if (!isSameMonthRange(startDate, endDate, currentYear)) {
    return `File doesn't belong to selected month!`;
  }
  return true;
};

const isNonEmpty = (data) => data && data.length > 0;

export const isValidFinanceParam = ({ fy, fm, fq, yearList }) => {
  if (!yearList.includes(fy)) {
    return false;
  }

  const current = currentYearMonth();
  if (fy === current.currentFYear) {
    if (fm === null && fq === null) {
      return false;
    }

    // When Current month is from Apr to Dec
    if (current.currentMonth > 2) {
      // queried month from next year(Jan-Mar) & greater or eq to current month is invalid
      if (fm && (fm >= current.currentMonth || fm < 3)) {
        return false;
      }

      // queried quarter is 4 or greater or eq to current month is invalid
      if (fq && (fq === 4 || fq * 3 + 2 > current.currentMonth)) {
        return false;
      }
    } else {
      // When Current month is from Jan to Mar

      // queried month from curr year & greater or eq to current month is invalid
      if (fm && fm < 2 && fm >= current.currentMonth) {
        return false;
      }

      // queried quarter is 4
      if (fq && fq === 4) {
        return false;
      }
    }
  }

  return true;
};
