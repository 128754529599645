export const convertToAsinData = (data) => {
  const defaultValue = {
    asinTcs: {},
    asinSell: {},
    asinQty: {},
  };

  data = data || [];

  const output = data.reduce((output, currData) => {
    const qty =
      currData["Transaction Type"] === "Shipment"
        ? +currData["Quantity"]
        : currData["Transaction Type"] === "Refund"
        ? -+currData["Quantity"]
        : 0;

    if (output.asinQty[currData["Asin"]]) {
      output.asinQty[currData["Asin"]] += qty;
    } else {
      output.asinQty[currData["Asin"]] = qty;
    }

    if (output.asinSell[currData["Asin"]]) {
      output.asinSell[currData["Asin"]] += +currData["Tax Exclusive Gross"];
    } else {
      output.asinSell[currData["Asin"]] = +currData["Tax Exclusive Gross"];
    }

    if (output.asinTcs[currData["Asin"]]) {
      output.asinTcs[currData["Asin"]] +=
        +currData["Tcs Igst Amount"] +
        +currData["Tcs Sgst Amount"] +
        +currData["Tcs Cgst Amount"];
    } else {
      output.asinTcs[currData["Asin"]] =
        +currData["Tcs Igst Amount"] +
        +currData["Tcs Sgst Amount"] +
        +currData["Tcs Cgst Amount"];
    }

    return output;
  }, defaultValue);

  return output;
};

export const getAsinList = (data) => {
  return new Set(data.map(currData => currData["Asin"]));
};
