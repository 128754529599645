export const validStatesAbbreviation = {
  'AP': '37-Andhra Pradesh',
  'AR': '12-Arunachal Pradesh',
  'AS': '18-Assam',
  'BR': '10-Bihar',
  'CT': '22-Chhattisgarh',
  'CG': '22-Chhattisgarh',
  'GA': '30-Goa',
  'GJ': '24-Gujarat',
  'HR': '06-Haryana',
  'HP': '02-Himachal Pradesh',
  'JK': '01-Jammu and Kashmir',
  'JH': '20-Jharkhand',
  'KA': '29-Karnataka',
  'KL': '32-Kerala',
  'MP': '23-Madhya Pradesh',
  'MH': '27-Maharashtra',
  'MN': '14-Manipur',
  'ML': '17-Meghalaya',
  'MZ': '15-Mizoram',
  'NL': '13-Nagaland',
  'OR': '21-Odisha',
  'PB': '03-Punjab',
  'RJ': '08-Rajasthan',
  'SK': '11-Sikkim',
  'TN': '33-Tamil Nadu',
  'TR': '16-Tripura',
  'UT': '05-Uttarakhand',
  'UK': '05-Uttarakhand',
  'UP': '09-Uttar Pradesh',
  'WB': '19-West Bengal',
  'AN': '35-Andaman and Nicobar Islands',
  'CH': '04-Chandigarh',
  'DH': '26-Dadra and Nagar Haveli and Daman and Diu',
  'DD': '26-Dadra and Nagar Haveli and Daman and Diu',
  'DL': '07-Delhi',
  'LD': '31-Lakshadweep',
  'PY': '34-Puducherry',
  'TG': '36-Telangana',
  'TS': '36-Telangana',
  'LA': '38-Ladakh',
  'OT': '97-Other Territory',
};

export const expenseFormStateList = {
  'AN': '35-Andaman and Nicobar Islands',
  'AP': '37-Andhra Pradesh',
  'AR': '12-Arunachal Pradesh',
  'AS': '18-Assam',
  'BR': '10-Bihar',
  'CH': '04-Chandigarh',
  'CT': '22-Chattisgarh',
  'DH': '26-Dadra and Nagar Haveli and Daman and Diu',
  'DD': '25-Daman and Diu',
  'DL': '07-Delhi',
  'GA': '30-Goa',
  'GJ': '24-Gujarat',
  'HR': '06-Haryana',
  'HP': '02-Himachal Pradesh',
  'JK': '01-Jammu and Kashmir',
  'JH': '20-Jharkhand',
  'KA': '29-Karnataka',
  'KL': '32-Kerala',
  'LA': '38-Ladakh',
  'LD': '31-Lakshadweep',
  'MP': '23-Madhya Pradesh',
  'MH': '27-Maharashtra',
  'MN': '14-Manipur',
  'ML': '17-Meghalaya',
  'MZ': '15-Mizoram',
  'NL': '13-Nagaland',
  'OR': '21-Odisha',
  'OT': '97-Other Territory',
  'PY': '34-Puducherry',
  'PB': '03-Punjab',
  'RJ': '08-Rajasthan',
  'SK': '11-Sikkim',
  'TN': '33-Tamil Nadu',
  'TG': '36-Telangana',
  'TR': '16-Tripura',
  'UP': '09-Uttar Pradesh',
  'UT': '05-Uttarakhand',
  'WB': '19-West Bengal',
}
