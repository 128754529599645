import { createContext, useState } from "react";
import { consoleLog } from "../helper/logs";

export const PopupContext = createContext({
  status: [],
  type: [],
  title: []
});

export const addAlertContext = createContext(() => { });
export const setPopupContext = createContext(() => { });
export const setStatusContext = createContext(() => { });

export const PopupProvider = ({ children }) => {
  consoleLog('rendering popup context provider');
  const [status, setStatus] = useState([]);
  const [type, setType] = useState("error");
  const [title, setTitle] = useState([]);

  const addAlert = (newTitle, newType = 'alert') => {
    if (title.indexOf(newTitle) === -1) {
      setTitle([...title, newTitle]);
      setType([...type, newType]);
      setStatus([...status, (newTitle && newTitle.length > 0 ? true : false)]);
    }
  }

  const setPopup = (newTitle, newType = 'alert') => {
    if (typeof newTitle === 'string') {
      setTitle([newTitle]);
      setType([newType]);
      setStatus(newTitle && newTitle.length > 0 ? [true] : [false]);
    } else {
      setTitle(newTitle);
      setType(newTitle.map(e => newType));
      setStatus(newTitle.map(e => e && e.length > 0));
    }
  }

  const value = { status, type, title };

  return <PopupContext.Provider value={value}>
    <addAlertContext.Provider value={addAlert}>
      <setPopupContext.Provider value={setPopup}>
        <setStatusContext.Provider value={setStatus}>
          {children}
        </setStatusContext.Provider>
      </setPopupContext.Provider>
    </addAlertContext.Provider>
  </PopupContext.Provider>
};