export const PLANS = {
  "FREE": {
    id: "FREE",
    validityInDays: 0,
    amount: 0,
    description: 'Free Plan (Lifetime)'
  },
  "BASIC_3M_PLAN": {
    id: "BASIC_3M_PLAN",
    validityInDays: 90,
    amount: 699, // 69900
    description: '3 Months Basic Plan'
  },
  "BASIC_1M_FREE": {
    id: "BASIC_1M_FREE",
    validityInDays: 30,
    amount: 0,
    description: '1 Month Free Trial'
  },
  "BASIC_2M_FREE": {
    id: "BASIC_2M_FREE",
    validityInDays: 60,
    amount: 0,
    description: '2 Months Free Trial'
  },
  "BASIC_12M_PLAN": {
    id: "BASIC_12M_PLAN",
    validityInDays: 365,
    amount: 2499, // 249900
    description: 'Annual Basic Plan'
  }
}

export const ORDER_STATUS = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  UNVERIFIED: "UNVERIFIED",
  PENDING: "PENDING"
}

export const BASIC_TRIAL_PLANS = ["BASIC_1M_FREE", "BASIC_2M_FREE"];
export const BASIC_PLANS = [...BASIC_TRIAL_PLANS, "BASIC_3M_PLAN", "BASIC_12M_PLAN"];