export const ACCOUNTS = {
  'Cost of Goods Sold': {
    'Cost of Goods sold': 'Cost of Goods sold',
    'Packaging Material': 'Packaging Material',
    'Job Costing': 'Job Costing',
    Labor: 'Labor',
    'Other Materials': 'Other Materials',
    Subcontractor: 'Subcontractor',
  },
  Expense: {
    'Advertising & Marketing': 'Advertising & Marketing',
    'Automobile Expense': 'Automobile Expense',
    'Bank Fees & Charges': 'Bank Fees & Charges',
    'Consultant Expenses': 'Consultant Expenses',
    'Credit Card Charges': 'Credit Card Charge',
    'Deprication Expenses': 'Deprication Expenses',
    'IT & Internet Expenses': 'IT & Internet Expenses',
    'Janitorial Expenses': 'Janitorial Expenses',
    'Meals & Entertainment': 'Meals & Entertainment',
    'Office Supplies': 'Office Supplies',
    'Other Expenses': 'Other Expenses',
    'Print & Stationery': 'Print & Stationery',
    'Raw Materials & Consumables': 'Raw Materials & Consumables',
    'Rent Expense': 'Rent Expense',
    'Repair & Maintenance': 'Repair & Maintenance',
    'Salaries & Wages': 'Salaries & Wages',
    'Transportation Expense': 'Transportation Expense',
    'Travel Expense': 'Travel Expense',
  },

  'Fixed Assets': {
    'Furniture & Other Equipments': 'Furniture & Other Equipments',
  },
};