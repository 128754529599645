import moment from "moment/moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Upload_error from "../components/atoms/svg/upload_error";
import Upload_success from "../components/atoms/svg/upload_success";
import * as XML from "xml-js";
import Upload_warn from "../components/atoms/svg/upload_warn";
import { consoleLog } from "../helper/logs";
import { parseCsvFile } from "../helper/parse-csv";
import { storeSaleParam } from "../helper/storage";
import { getFinancialYear, isDateAccessible } from "../helper/time";
import { isValidFile, isValidXml } from "../helper/validate";
import { convertToAsinData } from "../reducer/sales/asin";
import { storeSaleParam } from "../helper/storage";
import { getFinancialYear, isDateAccessible } from "../helper/time";
import { isValidFile } from "../helper/validate";
import { convertToAsinData, getAsinList } from "../reducer/sales/asin";
import { GetHsnDetail } from "../reducer/sales/hsn";
import { ApiContext } from "./api";
import { addAlertContext, setPopupContext } from "./popup";
import { getCurrentPlan } from "../helper/ordersubscription";

export const SalesContext = createContext({ selectedDate: null });

export const SalesProvider = ({ children }) => {
  consoleLog("rendering sales context provider");
  const [b2bData, setB2bData] = useState([]);
  const [cashXmlUpload, setCashXmlUpload] = useState([]);
  const [electronicXmlUpload, setElectronicXmlUpload] = useState([]);
  const [b2cData, setB2cData] = useState([]);
  const [hsnData, setHsnData] = useState();
  const [quarterAccess, setQuarterAccess] = useState(2);
  const [selectedDate, setSelectedDate] = useState();
  const [missingOrders, setMissingOrders] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const setPopup = useContext(setPopupContext);
  const location = useLocation();
  const { user, sales } = useContext(ApiContext);
  const addAlert = useContext(addAlertContext);
  const [yearList, setYearList] = useState([]);
  const [cashXmlUploadError, setCashXmlUploadError] = useState();
  const [electronicXmlUploadError, setElectronicXmlUploadError] = useState();
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [noOfXmlUpload, setNoOfXmlUpload] = useState(null);
  const [autoSettledTds, setAutoSettledTds] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setElectronicXmlUploadError("");
      setCashXmlUploadError("");
    }, 3000);
  }, [electronicXmlUploadError, cashXmlUploadError]);

  useEffect(() => {
    if (user.currentUser) {
      const yearList = user.getAccessibleFYears();
      setYearList(yearList);
    }
  }, [user.currentUser]);

  useEffect(() => {
    setB2bData(null);
    setB2cData(null);
    setCashXmlUpload([]);
    setElectronicXmlUpload([]);

    if (user.currentUser?.orderHistory && yearList.length) {
      const currentPlan = getCurrentPlan(user.currentUser.orderHistory)
      setQuarterAccess(currentPlan ? 8: 2);
      const monthMeta = searchParams.get("m");
      const yearMeta = searchParams.get("y");
      if (monthMeta && yearMeta) {
        const fYearMeta = getFinancialYear(yearMeta, monthMeta);
        const newDataStatus = isDateAccessible({
          m: monthMeta,
          y: yearMeta,
          yearList,
          quarterAccess: currentPlan ? 8: 2,
        });

        if (newDataStatus.valid) {
          setDate(yearMeta, monthMeta);
          storeSaleParam({ y: yearMeta, m: monthMeta });
          sales.getFile(monthMeta, fYearMeta).then((dataFile) => {
            setPopup("");

            if (dataFile) {
              if (dataFile.b2b) {
                setB2bData(dataFile.b2b);

                if (hsnData) {
                  updateHsnData(GetHsnDetail(dataFile.b2b, hsnData));
                } else {
                  sales.getHsnData().then((data) => {
                    updateHsnData(GetHsnDetail(dataFile.b2b, data ?? {}));
                  });
                }

                if (!dataFile.b2c) {
                  setPopup("You have only uploaded B2B file!");
                }
              } else {
                setB2bData(null);
              }
              if (dataFile.b2c) {
                setB2cData(dataFile.b2c);

                if (hsnData) {
                  updateHsnData(GetHsnDetail(dataFile.b2c, hsnData));
                } else {
                  sales.getHsnData().then((data) => {
                    updateHsnData(GetHsnDetail(dataFile.b2c, data ?? {}));
                  });
                }

                if (!dataFile.b2b) {
                  setPopup("You have only uploaded B2C file!");
                }
              } else {
                setB2cData(null);
              }
            } else {
              setB2bData(null);
              setB2cData(null);
            }
          });
        } else {
          addAlert(newDataStatus.errorMessage);

          searchParams.set("y", newDataStatus.y);
          searchParams.set("m", newDataStatus.m);
          setSearchParams(searchParams);
        }
      }
    }

    return () => {
      if (location.pathname.includes("sales")) {
        setPopup("");
      }
    };
  }, [searchParams, user.currentUser, yearList]);

  const getQuarterlyData = async () => {
    const monthMeta = selectedDate.getMonth();
    const yearMeta = selectedDate.getFullYear();
    const fYearMeta = getFinancialYear(yearMeta, monthMeta);
    const dataFiles =
      (await sales.getQuarterlyFiles(monthMeta, fYearMeta)) || {};

    return dataFiles;
  };
  const getXmlUploadedData = async (isUpdated = false) => {
    const monthMeta = searchParams.get("m");
    const yearMeta = searchParams.get("y");

    if (user.currentUser && yearMeta && monthMeta) {
      const data = await sales.getReconcileData(
        getFinancialYear(yearMeta, monthMeta),
        monthMeta
      );
      setMissingOrders(data?.missingOrders ?? null);
      setAutoSettledTds(data?.automatedSettledTDS ?? 0);
      setCheckSuccess(true);

      setNoOfXmlUpload({
        codFileCount: data?.codFileCount ?? 0,
        elecFileCount: data?.elecFileCount ?? 0,
      });
    }
  };
  useEffect(() => {
    getXmlUploadedData();
  }, [searchParams, user.currentUser]);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const uploadXml = (e, type) => {
    const fileType = [
      "text/plain",
      "application/xml",
      "application/octet-stream",
    ];
    const fileData = Array.from(e?.target?.files);
    fileData?.forEach((file) => {
      if (fileType.includes(file.type)) {
        const fileResult = readFileAsync(file);
        fileResult.then((item) => {
          const parsedXml = JSON.parse(
            XML?.xml2json(item, { JSON: true, compact: false, spaces: 2 })
          );
          const info =
            parsedXml?.elements?.[0]?.elements?.[2]?.elements?.[1]
              ?.elements?.[0]?.elements;

          const startDate = info?.filter(
            (item) => item.name === "StartDate"
          )?.[0]?.elements?.[0]?.text;
          const endDate = info?.filter((item) => item.name === "EndDate")?.[0]
            ?.elements?.[0]?.text;

          const date = new Date(selectedDate);
          const currentMonth = date.getMonth();
          const currentYear = date.getFullYear();
          const isValidFile = isValidXml(
            parsedXml,
            startDate,
            endDate,
            currentMonth,
            currentYear
          );

          // collating Order items
          const allOrderItem =
            parsedXml?.elements?.[0]?.elements?.[2]?.elements?.[1]?.elements?.filter(
              (item) => item.name === "Order"
            );

          // collating refund items
          const allRefundItem =
            parsedXml?.elements?.[0]?.elements?.[2]?.elements?.[1]?.elements?.filter(
              (item) => item.name === "Refund"
            );

          if (isValidFile) {
            if (type === "cashTransactions") {
              setCashXmlUpload((prev) => [
                ...prev,
                {
                  order: allOrderItem,
                  refund: allRefundItem,
                },
              ]);

              setNoOfXmlUpload({
                ...noOfXmlUpload,
                codFileCount: ++noOfXmlUpload.codFileCount,
              });
            } else if (type === "electronicTransactions") {
              setElectronicXmlUpload((prev) => [
                ...prev,
                {
                  order: allOrderItem,
                  refund: allRefundItem,
                },
              ]);
              setNoOfXmlUpload({
                ...noOfXmlUpload,
                elecFileCount: ++noOfXmlUpload.elecFileCount,
              });
            }
          } else {
            if (type === "cashTransactions") {
              setCashXmlUploadError(validity);
            } else if (type === "electronicTransactions") {
              setElectronicXmlUploadError(validity);
            }
          }
        });
      } else {
        if (type == "cashTransactions") {
          setCashXmlUploadError("File doesn't belong to selected month!");
        } else {
          setElectronicXmlUploadError("File doesn't belong to selected month!");
        }
      }
    });

    if (e?.target?.files) {
      e.target.value = "";
    }
  };

  const resolveOrders = () => {
    const reducedCashedPayments = [];
    const reducedElecPayments = [];

    // simplifying and collating the cash and electronic orders
    cashXmlUpload.forEach((item) => {
      item?.order?.forEach((order) => {
        reducedCashedPayments.push({
          "Order Id": order.elements?.[0]?.elements?.[0]?.text,
          "Transaction Type": "Shipment",
        });
      });
      item?.refund?.forEach((order) => {
        reducedCashedPayments.push({
          "Order Id": order.elements?.[0]?.elements?.[0]?.text,
          "Transaction Type": "Refund",
        });
      });
    });
    electronicXmlUpload.forEach((item) => {
      item?.order?.forEach((order) => {
        reducedElecPayments.push({
          "Order Id": order.elements?.[0]?.elements?.[0]?.text,
          "Transaction Type": "Shipment",
        });
      });
      item?.refund?.forEach((order) => {
        reducedElecPayments.push({
          "Order Id": order.elements?.[0]?.elements?.[0]?.text,
          "Transaction Type": "Refund",
        });
      });
    });

    const missingOrdersList = missingOrders ?? [];
    let mtrTdsValue = 0;

    // checking if missing order array exists, therefore we can check the missing list instead of the large mtr file
    const allMtrUpload = Array.isArray(missingOrders) && autoSettledTds
      ? missingOrdersList
      : [...(b2bData ?? []), ...(b2cData ?? [])].filter(
          (item) =>
            item["Transaction Type"] === "Shipment" ||
            item["Transaction Type"] === "Refund"
        );

    allMtrUpload?.forEach((item) => {
      const resolvedCashXmlUploads = reducedCashedPayments.filter(
        (prod) =>
          prod?.["Order Id"] && prod?.["Order Id"] === item?.["Order Id"]
      );

      const resolvedElectronicXmlUploads = reducedElecPayments.filter(
        (prod) =>
          prod?.["Order Id"] && prod?.["Order Id"] === item?.["Order Id"]
      );

      let autoResolvedUploads;

      //  tds calculation for cash order uploads
      if (resolvedCashXmlUploads.length) {
        autoResolvedUploads = resolvedCashXmlUploads.find(
          (data) => data?.["Transaction Type"] === item?.["Transaction Type"]
        );

        if (
          autoResolvedUploads?.["Order Id"] &&
          autoResolvedUploads["Transaction Type"] === "Shipment"
        ) {
          const mtrTds =
            Math.abs(+item["Tcs Igst Amount"] ?? 0) ||
            Math.abs(+item["Tcs Sgst Amount"] ?? 0) +
              Math.abs(+item["Tcs Cgst Amount"] ?? 0);

          mtrTdsValue += mtrTds;
        }
      }
      //  tds calculation for electronic order uploads
      if (resolvedElectronicXmlUploads?.length) {
        autoResolvedUploads = resolvedElectronicXmlUploads.find(
          (data) => data?.["Transaction Type"] === item?.["Transaction Type"]
        );
        if (
          autoResolvedUploads?.["Order Id"] &&
          autoResolvedUploads["Transaction Type"] === "Shipment"
        ) {
          const mtrTds =
            Math.abs(+item["Tcs Igst Amount"] ?? 0) ||
            Math.abs(+item["Tcs Sgst Amount"] ?? 0) +
              Math.abs(+item["Tcs Cgst Amount"] ?? 0);
          mtrTdsValue += mtrTds;
        }
      }
      const cashItem = reducedCashedPayments.find(
        (prod) =>
          prod?.["Order Id"] && prod?.["Order Id"] === item?.["Order Id"]
      );

      const elecItem = reducedElecPayments.find(
        (prod) =>
          prod?.["Order Id"] && prod?.["Order Id"] === item?.["Order Id"]
      );
      let uploads;
      if (cashItem?.["Order Id"]) {
        uploads = cashItem;
      } else if (elecItem?.["Order Id"]) {
        uploads = elecItem;
      }

      // Check if missing item has already been registered. If so adding tds to previous tds
      if (
        item.hasOwnProperty("isResolved") ||
        (!uploads && item?.["Order Id"])
      ) {
        const index = missingOrdersList?.findIndex(
          (order) => item["Order Id"] === order["Order Id"]
        );
        console.log(index);
        if (index >= 0) {
          missingOrdersList[index] = {
            ...missingOrdersList?.[index],
            tds:
              item.tds ??
              missingOrdersList[index].tds +
                (Math.abs(+item["Tcs Igst Amount"] ?? 0) ||
                  Math.abs(+item["Tcs Sgst Amount"] ?? 0) +
                    Math.abs(+item["Tcs Cgst Amount"] ?? 0)),
          };
        } else {
          missingOrdersList.push({
            "Order Id": item["Order Id"],
            isResolved: false,
            "Transaction Type": item["Transaction Type"],
            "Principal Amount Basis": item["Principal Amount Basis"],
            tds:
              item["Transaction Type"] === "Refund"
                ? 0
                : item.tds ??
                  (Math.abs(+item["Tcs Igst Amount"] ?? 0) ||
                    Math.abs(+item["Tcs Sgst Amount"] ?? 0) +
                      Math.abs(+item["Tcs Cgst Amount"] ?? 0)),
          });
        }
      }
    });

    const fYearMeta = getFinancialYear(
      selectedDate?.getFullYear(),
      selectedDate?.getMonth()
    );

    sales.storeReconcileData(
      {
        codFileCount: noOfXmlUpload.codFileCount,
        elecFileCount: noOfXmlUpload.elecFileCount,
        missingOrders: missingOrdersList,
        automatedSettledTDS: autoSettledTds + mtrTdsValue,
      },
      selectedDate.getMonth(),
      fYearMeta
    );
    setAutoSettledTds(autoSettledTds + mtrTdsValue);

    setMissingOrders(missingOrdersList);
  };

  const clearAllUploads = () => {
    const fYearMeta = getFinancialYear(
      selectedDate?.getFullYear(),
      selectedDate?.getMonth()
    );
    setAutoSettledTds(0);
    setElectronicXmlUpload([]);
    setCashXmlUpload([]);
    sales.storeReconcileData(
      {
        automatedSettledTDS: 0,
        elecFileCount: 0,
        codFileCount: 0,
        missingOrders: [],
      },
      selectedDate.getMonth(),
      fYearMeta
    );
    getXmlUploadedData();
  };

  const uploadFile = async (target, type) => {
    const file = target.files[0];

    if (file) {
      const data = await parseCsvFile(file);
      const date = new Date(selectedDate);
      const month = date.getMonth();
      const year = date.getFullYear();
      const financialYear = getFinancialYear(year, date.getMonth());
      const filteredData = data.filter((d) => d["Seller Gstin"]);
      const numberOfAsin = getAsinList(data).size;

      try {
        if (
          isValidFile(
            filteredData,
            type,
            month,
            year,
            user.currentUser.gst_no,
            numberOfAsin
          )
        ) {
          const isUploaded = await sales.storeFile(
            file,
            type,
            date.getMonth(),
            financialYear
          );
          await sales.storeAsinData(
            convertToAsinData(filteredData),
            type,
            date.getMonth(),
            financialYear
          );
          if (type === "b2b") setB2bData(filteredData);
          if (type === "b2c") setB2cData(filteredData);

          if (hsnData) {
            const convertedHsnData = GetHsnDetail(filteredData, hsnData);
            updateHsnData(convertedHsnData);
            storeHsnData(convertedHsnData);
          } else {
            sales.getHsnData().then((data) => {
              const convertedHsnData = GetHsnDetail(filteredData, data ?? {});
              updateHsnData(convertedHsnData);
              storeHsnData(convertedHsnData);
            });
          }

          return {
            isUploaded: isUploaded,
            message: `MTR_${type.toUpperCase()}-${moment(date).format(
              "MMMM"
            )}...csv`,
            icon: <Upload_success />,
          };
        }

        return { isUploaded: false };
      } catch (error) {
        console.warn(error);

        return {
          isUploaded: false,
          message: error.message,
          icon:
            error.message === "This file is corrupted!" ? (
              <Upload_error />
            ) : (
              <Upload_warn />
            ),
        };
      }
    }
  };

  const updateHsnData = (data) => {
    setHsnData(data);
  };

  const storeHsnData = (data) => {
    sales.storeHsnData(data);
  };

  const setDate = (year, month) => {
    try {
      const date = new Date(+year, +month, 2, 2, 2, 2, 2);

      if (date) {
        if (selectedDate && selectedDate.getTime() === date.getTime()) {
        } else {
          setSelectedDate(date);
          return date;
        }
      }
    } catch {
      console.error;
    }

    return null;
  };


  const value = {
    b2bData,
    b2cData,
    getQuarterlyData,
    hsnData,
    quarterAccess,
    selectedDate,
    updateHsnData,
    storeHsnData,
    uploadFile,
    uploadXml,
    electronicXmlUpload,
    cashXmlUpload,
    setCashXmlUpload,
    resolveOrders,
    missingOrders,
    getXmlUploadedData,
    cashXmlUploadError,
    electronicXmlUploadError,
    yearList,
    checkSuccess,
    setCheckSuccess,
    selectedOrders,
    noOfXmlUpload,
    setSelectedOrders,
    clearAllUploads,
    autoSettledTds,
  };

  return (
    <SalesContext.Provider value={value}>{children}</SalesContext.Provider>
  );
};
