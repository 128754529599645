import Papa from 'papaparse'

export const parseCsvFile = (csvFile) => {

  return new Promise((resolve, reject) => {
    try {
      Papa.parse(csvFile, {
        header: true,
        complete: (results) => {
          if (results.data) {
            resolve(results.data);
          }
        },
      });
    } catch (error) {
      reject(error);
    }
  })
}