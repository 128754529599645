import { createContext, useCallback, useState } from "react";
import { consoleLog } from "../helper/logs";

export const GlobalContext = createContext({
  popup: {
    status: [false],
    type: 'error',
    title: [''],
    setPopup: () => { },
    setStatus: () => { }
  },
  modal: { currentModal: '', setCurrentModal: () => { } },
  sidebar: { isOpened: false, setIsOpened: () => { } },
  loader: { isLoading: [], setIsLoading: () => { } }
});

export const setLoaderContext = createContext(() => { });

export const GlobalProvider = ({ children }) => {
  consoleLog('rendering global context provider');
  const [status, setStatus] = useState([false]);
  const [type, setType] = useState("error");
  const [title, setTitle] = useState(["This is a alert"]);
  const [currentModal, setCurrentModal] = useState('');
  const [isLoading, setLoader] = useState([false]);

  const setPopup = useCallback((title, type = 'error') => {
    setType(type);

    if (typeof title === 'string') {
      setTitle([title]);
      setStatus(title && title.length > 0 ? [true] : [false]);
    } else {
      setTitle(title);
      setStatus(title.map(e => e && e.length > 0));
    }
  }, []);

  const value = { popup: { status, setStatus, type, title, setPopup }, modal: { currentModal, setCurrentModal }, loader: { isLoading } };

  return <GlobalContext.Provider value={value}>
    <setLoaderContext.Provider value={setLoader}>
      {children}
    </setLoaderContext.Provider>
  </GlobalContext.Provider>
};