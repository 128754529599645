export const expenseAmount = (expenseItem) => {
  var sum = 0;
  if (expenseItem?.import) {
    sum = expenseItem?.totalInvoiceAmount;
  } else {
    expenseItem.items?.forEach((item) => {
      try {
        if (item?.expenseAmount !== null && item?.expenseAmount !== "") {
          const parsedValue = parseFloat(item?.expenseAmount);
          sum += parsedValue;
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  return sum;
};

export const expenseTax = (expenseItem) => {
  if (expenseItem?.import) {
      const taxRate = isNaN(+expenseItem.tax) ? 0 : +expenseItem.tax;

      return (+expenseItem?.assessedValue + +expenseItem.totalDuty) * taxRate / 100;
  } else {
    
    return expenseItem?.items.reduce((prev, curr) => {
      const taxRate = isNaN(+curr.tax) ? 0 : +curr.tax;
      return prev + (+curr.expenseAmount * taxRate) / (100 + taxRate);
    }, 0);
  }
};