// remember to comment all logs out before release
export const consoleLog = (message, ...args) => {
  if (window.enabledLogs) {
    console.log(message, ...args);
  }
}

export const consoleWarn = (message, ...args) => {
  console.warn(message, ...args);
}

export const consoleError = (message, ...args) => {
  console.error(message, ...args);
}

export const specialLog = (message, ...args) => {
  if (window.enabledSpecialLogs) {
    console.info(message, ...args);
  }
}