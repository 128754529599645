export default () => {
  return (
    <div
      className="!h-full !w-full block !fixed z-50 !overflow-x-hidden !bg-[#000000b2] !left-0 !top-0 !duration-500 "
      data-cy="overlay"
    >
      <div
        id="wave"
        className="relative mt-[50vh] text-center h-full w-full ml-auto mr-auto last:delay-[-0.9s] even:delay-[-1.1s] "
      >
        <span className="inline-block w-3 h-3 rounded-full bg-white animate-wave "></span>
        <span className="inline-block w-3 h-3 rounded-full animate-wave bg-white [animation-delay:-1.1s]"></span>
        <span className="inline-block w-3 h-3 rounded-full animate-wave  bg-white [animation-delay:-0.9s]"></span>
      </div>
    </div>
  );
};
