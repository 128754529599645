import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { initializeApp } from "firebase/app";
import { config } from "../config";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import {
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  connectAuthEmulator,
} from "firebase/auth";
import {
  // clearIndexedDbPersistence,
  getDocFromCache,
  connectFirestoreEmulator,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
  getDoc,
  initializeFirestore,
  serverTimestamp,
  Timestamp,
  collection,
  // persistentLocalCache,
  runTransaction,
  memoryLocalCache
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  deleteObject,
  getBlob,
  getStorage,
  ref,
  uploadBytes,
  connectStorageEmulator,
} from "firebase/storage";

import { BASIC_TRIAL_PLANS, ORDER_STATUS, PLANS } from "../constants/pricing";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  consoleError,
  consoleLog,
  consoleWarn,
  specialLog,
} from "../helper/logs";
import moment from "moment/moment";
import { parseCsvFile } from "@helper/parse-csv";
import Loader from "../components/molecules/loader/loader";
import { GlobalContext, setLoaderContext } from "./global";
import { addAlertContext } from "./popup";
import { clearStorage, initStorage } from "@helper/storage";
import { RAZORPAY_OPTIONS } from "../constants/razorpay";
import InvoiceTemplate from "@organisms/invoice-template"
import { addOrder, getCurrentPlan } from "@helper/ordersubscription";
import { currentYearMonth, getLastXFinancialYears } from "@helper/time";

export const ApiContext = createContext({
  analytics: {
    logData: () => { },
  },
  user: {
    updatePlan: () => { },
    deleteAccount: () => { },
    isGstIdExist: () => { },
    signin: () => { },
    signup: () => { },
    signout: () => { },
    resetPassword: () => { },
    getAccessibleFYears: () => { },
    getUserPicture: () => { },
    setUserPicture: () => { },
    currentUser: null,
    isEmailVerified: false,
    updateUserData: () => { },
  },
  sales: {
    deleteFile: () => { },
    getAsinData: () => { },
    getFile: () => { },
    getHsnData: () => { },
    getQuarterlyFiles: () => { },
    storeAsinData: () => { },
    storeFile: () => { },
    storeHsnData: () => { },
    storeReconcileData: async (data, month, year) => { },
    getReconcileData: async (year, month) => { },

    updateReconcileData: async (year, month, data) => { },
  },
  purchase: {
    getPurchaseData: () => { },
    storePurchaseData: () => { },
  },
  finance: {
    getFinanceData: () => { },
    storeFinanceData: () => { },
  },
  support: {
    createSupportRequest: () => { },
  },
  partner: { createPartnerRequest: () => { } },
});

export const ApiProvider = ({ children }) => {
  consoleLog("rendering api context provider");

  const [searchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { loader } = useContext(GlobalContext);
  const setLoader = useContext(setLoaderContext);
  const addAlert = useContext(addAlertContext);

  const [app] = useState(() => initializeApp(config.firebase));
  const [db] = useState(() => initializeFirestore(app, { localCache: memoryLocalCache({}) }));
  const [storage] = useState(() => getStorage(app));
  const [analytics] = useState(() => getAnalytics(app));
  const [auth] = useState(() => getAuth(app));
  const [apiLoaders] = useState([])

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      console.log("connecting to emulators");
      connectFirestoreEmulator(db, "127.0.0.1", 8080);
      connectStorageEmulator(storage, "127.0.0.1", 9199);
      connectAuthEmulator(auth, "http://127.0.0.1:9099");
    }

    // clearIndexedDbPersistence(db);


  }, []);

  // this method is created so multiple loaders can coexist without race condition

  const setIsLoading = (value, debug) => {
    if (value) {
      apiLoaders.push(debug)
    } else {
      apiLoaders.pop()
    }

    consoleLog(value, debug, [...apiLoaders]);
    setLoader([...apiLoaders]);
  };

  useEffect(() => {
    setIsLoading(true, "auth");
    let firstLoad;
    onAuthStateChanged(auth, (user) => {
      if (!firstLoad) {
        firstLoad = true;
        setIsLoading(false, "auth");
      }

      if (user && user.displayName) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        getUserData()?.then((data) => setCurrentUser(data));
        setIsEmailVerified(user.emailVerified);
        // ...
      } else {
        // User is signed out
        // ...
        setCurrentUser(null);
        setIsEmailVerified(false);

        if (
          location.pathname !== "/signup" &&
          location.pathname !== "/signin"
        ) {
          console.warn("redirecting to signin", location);
          navigate("/signin");
        }
      }
    });
  }, []);

  const logData = ({ event, data }) => {
    if (!data) {
      logEvent(analytics, event);
    } else {
      logEvent(analytics, event, { ...data });
    }
  };

  const signin = async ({ email, password }) => {
    setIsLoading(true, "signin");
    initStorage();
    // return.user
    const userData = await signInWithEmailAndPassword(auth, email, password)
      .then((data) => {
        // * check email is verified ?
        if (!auth.currentUser.emailVerified) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              addAlert(`To login, kindly verify your e-mail id!`);
              setIsLoading(false, "signin-sendEmailVerification-success");
            })
            .catch((err) => {
              addAlert("SEND EMAIL VERIFICATION ERROR ==>" + err.message);
              setIsLoading(false, "signin-sendEmailVerification-error");
            });

          return null;
        } else {
          setIsLoading(false, "signin-success");
          return data;
        }
      })
      .catch((err) => {
        setIsLoading(false, "signin-error");
        console.error(err);
        if (err.message === "Firebase: Error (auth/wrong-password).") {
          addAlert("Wrong password");
        } else if (err.message === "Firebase: Error (auth/user-not-found).") {
          addAlert("You are not registered, Please Register with us.");
        }

        return null;
      });

    if (userData?.user?.uid && !currentUser) {
      getUserData()?.then((data) => setCurrentUser(data));
    }

    return userData?.user?.uid ? true : false;
  };

  const isGstIdExist = async (gstId) => {
    setIsLoading(true, "isGstIdExist");
    const docRef = doc(db, gstId, "exist");
    const docSnap = await getDoc(docRef);
    setIsLoading(false, "isGstIdExist");
    return docSnap.exists();
  };

  const signup = async ({
    email,
    password,
    gstNo,
    userState,
    occupation,
    whatsappNum,
  }) => {
    setIsLoading(true, "signup");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      consoleLog("createUserWithEmailAndPassword ==>", userCredential);

      if (auth.currentUser) {
        // * send email to verify
        await sendEmailVerification(auth.currentUser);

        if (gstNo) {
          await updateProfile(auth.currentUser, { displayName: gstNo });
          // need next line to force update token so displayName is updated
          await auth.currentUser.getIdToken(true);
          const docRef = doc(db, gstNo, "exist");
          await setDoc(docRef, {});
        }

        // * adding data to that user
        const subscriptionDate = Timestamp.now();
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          email: email,
          gst_no: gstNo,
          states: [userState],
          owner: {
            name: email.split("@")[0],
            occupation: occupation,
          },
          whatsapp_number: whatsappNum,
          orderHistory: [{
            type: searchParams.get("p") === "1" ? PLANS.BASIC_2M_FREE.id : PLANS.FREE.id,
            orderId: 'NA',
            subscriptionDate,
            startDate: subscriptionDate,
            validityInDays: searchParams.get("p") === "1" ? PLANS.BASIC_2M_FREE.validityInDays : PLANS.FREE.validityInDays
          }]
        });
      }

      // user needs verify email and signin to be able to access all pages
      setCurrentUser(null);
      sendMail({ email, template: "wellcome-mail" });
      setIsLoading(false, "signup-success");
      return true;
    } catch (error) {
      console.error("Error ==>", error.message);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        addAlert(
          "User is already registered, Please login or retry with different email id"
        );
      } else if (error.message === "Firebase: Error (auth/invalid-email).") {
        addAlert("Invalid Email");
      } else {
        addAlert("Signup ERROR ==> " + error.message);
      }
      consoleError(error);
      setIsLoading(false, "signup-error");

      return false;
    }
  };

  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const signout = async () => {
    setIsLoading(true, "signout");
    clearStorage();
    await signOut(auth);
    setIsLoading(false, "signout");
  };

  const deleteFireStore = async () => {
    setIsLoading(true, `deleting user firestore data`);
    await deleteDoc(doc(db, auth.currentUser.displayName, "asin"));
    await deleteDoc(doc(db, auth.currentUser.displayName, "hsnData"));
    await deleteDoc(doc(db, auth.currentUser.displayName, "purchases"));
    await deleteDoc(doc(db, auth.currentUser.displayName, "csvSuffix"));
    const gstRef = doc(db, auth.currentUser.displayName, "exist");
    await setDoc(
      gstRef,
      { deleted: serverTimestamp(), userId: auth.currentUser.uid },
      { merge: true }
    );
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    await deleteDoc(userDocRef);

    setIsLoading(false, `user firestore data deleted`);
  };

  const deleteAccount = async (pswd) => {
    setIsLoading(true, "deleteUser");
    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        pswd
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await deleteFireStore();
      await deleteUser(auth.currentUser);
      clearStorage();
      setIsLoading(false, "deleteUser: success");
    } catch (error) {
      setIsLoading(false, "deleteUser: failure");
      consoleError(error.message);
    }
  };

  const getUserData = async () => {
    setIsLoading(true, "getUserData");
    const docRef = doc(db, "users", auth.currentUser.uid);

    try {
      const doc = await getDocFromCache(docRef);
      const returnData = doc.data();
      consoleLog("Cached User data:", doc.data());

      if (returnData) {
        setIsLoading(false, "getUserData-cached");
        return returnData;
      }
    } catch (e) {
      consoleLog("Error getting cached User:", e);
    }

    specialLog("Fetching user");
    try {
      const docSnap = await getDoc(docRef);
      setIsLoading(false, "getUserData-server");
      if (docSnap?.exists()) {
        const userData = docSnap.data();

        // migrating existing users to new setup and downgrading them to free plan.
        if (userData.plan_subscription_date && !userData.orderHistory) {
          const subscriptionDate = Timestamp.now();
          const orderHistory = [{
            type: userData.plan_type ? PLANS.BASIC_2M_FREE.id : PLANS.FREE.id,
            orderId: 'NA',
            subscriptionDate,
            startDate: subscriptionDate,
            validityInDays: userData.plan_type ? PLANS.BASIC_2M_FREE.validityInDays : PLANS.FREE.validityInDays
          }];

          delete userData.plan_type;
          delete userData.plan_subscription_date;

          await setDoc(doc(db, "users", auth.currentUser.uid), {
            ...userData,
            orderHistory
          }, { merge: false });
        }

        return userData;
      } else {
        console.error("No such User!", docSnap);
        addAlert(
          "User data not found, please contact us at help@fliqbook.in!!"
        );
        return null;
      }
    } catch (error) {
      consoleError(error.code, error.message);
      setIsLoading(false, "getUserData-error");

      return null;
    }
  };

  const sendConfirmationMail = async (order) => {
    setIsLoading(true, "sendConfirmationMail");
    const doc = new jsPDF("", "mm", [210, 270]);
    doc.html(ReactDOMServer.renderToString(<div style={{ height: "100vh", width: "720px" }}>
      <InvoiceTemplate invoiceData={order} user={currentUser} />
    </div>), {
      html2canvas: {
        scale: 0.25,
      },
      x: 0,
      y: 0,
      margin: 10,

      callback: async function (doc) {
        const file = doc.output("datauri");

        if (auth.currentUser.uid) {
          await addDoc(collection(db, "mail"), {
            to: [`${currentUser.email}`],
            template: {
              name: "payment_success",
              data: {
                filename: `${order.orderId}`,
                file: file,
                username: `${currentUser.owner?.name}`,
                amount: `${order.amount / 100}`
              },
            },
          }).then(() => {
            setIsLoading(false, "sendConfirmationMail");
            navigate("/profile");
          });
        }
      },
    });
  }

  const createOrder = async (plan) => {
    return fetch('https://api.fliqbook.in/createOrder', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: plan.amount,
        receipt: `${auth.currentUser.displayName}_${plan.id}_${currentUser.orderHistory.length}`
      })
    })
      .then(res => res.json())
      .catch(error => consoleWarn("razorpay order error", error.code, error.message))
  }

  const verifySignature = async (successResponse, orderId) => {
    return fetch(`https://api.fliqbook.in/verifySignature?razorpay_order_id=${orderId}&razorpay_payment_id=${successResponse.razorpay_payment_id}&razorpay_signature=${successResponse.razorpay_signature}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .catch(error => consoleWarn("razorpay order error", error.code, error.message))
  }

  const onSuccessfulPayment = async (successResponse, orderId, plan) => {
    const verifySignatureResponse = await verifySignature(successResponse, orderId);
    const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = successResponse;
    const invoiceId = await updateInvoiceCounter(plan.amount)

    const paidOrder = {
      amount: plan.amount,
      type: plan.id,
      orderId: orderId,
      invoiceId,
      validityInDays: plan.validityInDays,
      status: verifySignatureResponse.signatureIsValid ? ORDER_STATUS.SUCCESS : ORDER_STATUS.UNVERIFIED,
      razorpay: {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature
      }
    }

    updateUserData({ orderHistory: addOrder(currentUser.orderHistory, paidOrder) });
    sendConfirmationMail(paidOrder);
    // logData({ event: "purchase", data: ""});

    if (!verifySignatureResponse.signatureIsValid) {
      alert(verifySignatureResponse.msg)
    }
  }

  // wellcome-mail, upgrade-plan
  const sendMail = async ({ email, template }) => {
    addDoc(collection(db, "mail"), {
      to: [`${email}`],
      template: {
        name: template,
      }
    })
  }

  const updatePlan = async ({ plan }) => {
    if (BASIC_TRIAL_PLANS.includes(plan.id) && currentUser.orderHistory?.find(order => order.type === plan.id)) {
      alert('You have already used your free trial');

      return;
    }

    if (plan.amount) {
      // 1: add razorpay script if not loaded
      if (!window.Razorpay) {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
      }

      // 2: create order
      setIsLoading(true, "createOrder");
      const order = await createOrder(plan);
      setIsLoading(false, "createOrder");
      // logData({ event: "begin_checkout", data: ""});


      // 3: open payment modal
      const razorpayOptions = {
        ...RAZORPAY_OPTIONS,
        modal: {
          ...RAZORPAY_OPTIONS.modal,
          ondismiss: () => {
            // const pendingOrder = {
            //   amount: plan.amount,
            //   type: plan.id,
            //   orderId: order.id,
            //   validityInDays: plan.validityInDays,
            //   status: ORDER_STATUS.PENDING
            // }

            // updateUserData({ orderHistory: addOrder(currentUser.orderHistory, pendingOrder) });
          }
        },
        key: order.k,
        amount: plan.amount,
        customer_id: auth.currentUser.id,
        description: plan.description,
        order_id: order.id,
        handler: (response) => {
          // 4a. verify signature and update plan
          onSuccessfulPayment(response, order.id, plan);
        },
        prefill: {
          name: currentUser?.owner?.name ?? "",
          email: currentUser?.email,
          contact: currentUser?.whatsapp_number ?? 9000090000
        },
        notes: {
          gstId: auth.currentUser.displayName,
          business: currentUser?.business ?? ""
        },
      }
      const rzp1 = new window.Razorpay(razorpayOptions);
      rzp1.open();

      rzp1.on('payment.failed', function (response) {
        //4b. store order with payment status failed
        consoleError(response.error);
        // const failedOrder = {
        //   type: plan.id,
        //   orderId: order.id,
        //   validityInDays: plan.validityInDays,
        //   status: ORDER_STATUS.FAILED
        // };

        // updateUserData({ orderHistory: addOrder(currentUser.orderHistory, failedOrder) });
      });

    } else {
      const freeOrder = {
        type: plan.id,
        orderId: "NA",
        validityInDays: plan.validityInDays
      };
      await updateUserData({ orderHistory: addOrder(currentUser.orderHistory, freeOrder) });
      sendMail({ email: currentUser?.email, template: "upgrade-plan" });
    }

  }

  const updateUserData = async (newObject) => {
    setIsLoading(true, "updateUserData");
    if (newObject.gst_no && newObject.gst_no !== auth.currentUser.displayName) {
      // soft delete previous gst data
      const gstRef = doc(db, auth.currentUser.displayName, "exist");
      await setDoc(gstRef, { deleted: serverTimestamp() }, { merge: true });

      await updateProfile(auth.currentUser, { displayName: newObject.gst_no });
      // need next line to force update token so displayName is updated
      await auth.currentUser.getIdToken(true);
      const newGstRef = doc(db, auth.currentUser.displayName, "exist");
      await setDoc(newGstRef, {});
    }
    const docRef = doc(db, "users", auth.currentUser.uid);
    await setDoc(docRef, newObject, { merge: true });
    setCurrentUser({ ...currentUser, ...newObject });
    specialLog("Updating user");
    setIsLoading(false, "updateUserData");
  };

  const getAccessibleFYears = () => {
    if (!getCurrentPlan(currentUser.orderHistory)) {
      const { currentMonth, currentFYear } = currentYearMonth();

      if (currentMonth > 3 && currentMonth < 7) {
        return getLastXFinancialYears(2);
      }

      return [currentFYear];
    }

    return getLastXFinancialYears(3);
  };

  const getUserPicture = async (filename) => {
    try {
      const b2bFileRef = ref(
        storage,
        `users/${auth.currentUser.uid}/${filename}`
      );
      const snapShot = await getBlob(b2bFileRef);
      const urlCreator = window.URL || window.webkitURL;

      return urlCreator.createObjectURL(snapShot);
    } catch (error) {
      consoleWarn("User picture error ", error.code, error.message);
    }
  };

  const setUserPicture = async (file) => {
    if (auth.currentUser && file.name) {
      const fileRef = ref(
        storage,
        `users/${auth.currentUser.uid}/${file.name}`
      );
      setIsLoading(true, "setUserPicture");
      try {
        const snapshot = await uploadBytes(fileRef, file, {
          cacheControl: "public, max-age=31536000",
        });
        specialLog("file uploaded: ", snapshot);
        setIsLoading(false, "setUserPicture-success");

        return true;
      } catch (error) {
        consoleError(error.code, error.message);
        addAlert(
          "Image could not be uploaded, please try again later or contact us at support@fliqbook.in"
        );
        setIsLoading(false, "setUserPicture-error");
      }
    }

    return false;
  };

  const updateInvoiceCounter = async (amount) => {
    try {
      let invoiceId;
      await runTransaction(db, async (transaction) => {
        const { currentFYear } = currentYearMonth();
        const invoiceCounterRef = doc(db, "counters", `invoiceCounter-${currentFYear}`)
        const invoiceCounter = await transaction.get(invoiceCounterRef);
        if (!invoiceCounter.exists()) {
          const invoiceNumber = `${currentFYear.slice(2,4)}${currentFYear.slice(5,7)}00001`;
          transaction.set(invoiceCounterRef, { count: +invoiceNumber, amount });
          invoiceId = invoiceNumber;
        } else {
          const newInvoiceCounter = invoiceCounter.data().count + 1;
          const updatedAmount = invoiceCounter.data().amount + amount;
          transaction.update(invoiceCounterRef, { count: newInvoiceCounter, amount: updatedAmount });
          invoiceId = newInvoiceCounter;
        }

      });

      return invoiceId;
    } catch (e) {
      consoleWarn("Transaction failed: ", e);
    }
  }

  const getData = async ({
    documentId,
    fieldKey = undefined,
    subFieldKey = undefined,
  }) => {
    consoleLog("Getting data ", documentId, fieldKey, subFieldKey);
    const dataConverter = {
      fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        let outputData = { ...data };

        if (fieldKey !== undefined && subFieldKey !== undefined) {
          outputData =
            data[fieldKey] && data[fieldKey][subFieldKey]
              ? data[fieldKey][subFieldKey]
              : null;
        } else if (fieldKey !== undefined) {
          outputData = data[fieldKey] || null;
        }

        return outputData;
      },
    };
    const docRef = doc(
      db,
      auth.currentUser.displayName,
      documentId
    ).withConverter(dataConverter);

    // Get a document, forcing the SDK to fetch from the offline cache.
    try {

      const doc = await getDocFromCache(docRef);
      const returnData = doc.data();
      consoleLog("Cached document data:", doc.data(), `${documentId}/${fieldKey}/${subFieldKey}-cached`);

      if (returnData) {
        return returnData;
      }
    } catch (e) {
      consoleLog("Error getting cached document:", e, `${documentId}/${fieldKey}/${subFieldKey}-cached`);
    }

    // If offline cache not found, fetch doc from server
    specialLog("Fetching data from server", documentId, fieldKey, subFieldKey);
    try {
      setIsLoading(true, `getData: ${documentId}/${fieldKey}/${subFieldKey}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        consoleLog("Document data:", docSnap.data());
        setIsLoading(
          false,
          `getData: ${documentId}/${fieldKey}/${subFieldKey}-server`
        );
        return docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        setIsLoading(
          false,
          `getData: ${documentId}/${fieldKey}/${subFieldKey}-not-found`
        );
        setIsLoading(
          false,
          `getData: ${documentId}/${fieldKey}/${subFieldKey}-undefined`
        );
        consoleWarn("No such document!", docSnap);

        return null;
      }
    } catch (error) {
      setIsLoading(
        false,
        `getData: ${documentId}/${fieldKey}/${subFieldKey}-error`
      );
      consoleWarn("Something went wrong!", error.code, error.message);
    }
  };

  const updateData = async ({
    documentId,
    fieldKey = undefined,
    subFieldKey = undefined,
    newObject,
  }) => {
    const newDocData = newObject;
    setIsLoading(true, `setData: ${documentId}/${fieldKey}/${subFieldKey}`);
    const docRef = doc(db, auth.currentUser.displayName, documentId);
    specialLog("updating data: ", `${documentId}/${fieldKey}/${subFieldKey}`);

    if (fieldKey !== undefined && subFieldKey !== undefined) {
      await setDoc(
        docRef,
        { [fieldKey]: { [subFieldKey]: newDocData } },
        { merge: true }
      );
    } else if (fieldKey !== undefined) {
      await setDoc(docRef, { [fieldKey]: newDocData }, { merge: true });
    } else {
      await setDoc(docRef, newDocData, { merge: true });
    }
    setIsLoading(false, `setData: ${documentId}/${fieldKey}/${subFieldKey}`);
  };

  const storeCacheBuster = async (fileData, type, month, fYear) => {
    const storeData =
      (await getData({
        documentId: "csvSuffix",
        fieldKey: fYear,
        subFieldKey: month,
      })) || {};

    storeData[type] = fileData;
    await updateData({
      documentId: "csvSuffix",
      fieldKey: fYear,
      subFieldKey: month,
      newObject: storeData,
    });
  };

  const getCacheBuster = async ({ fYear, month }) => {
    const storeData = await getData({
      documentId: "csvSuffix",
      fieldKey: fYear,
      subFieldKey: month,
    });

    if (storeData) {
      return storeData;
    }

    return { b2b: "", b2c: "" };
  };

  const storeFile = async (file, type, month, fYear) => {
    const cacheBuster = Date.now();
    setIsLoading(true, "storeFile");
    try {
      await storeCacheBuster(cacheBuster, type, month, fYear);
      const fileRef = ref(
        storage,
        `${auth.currentUser.displayName
        }/${fYear}/${month}/MTR_${type.toUpperCase()}_${moment({
          M: month,
        }).format("MMMM")}${cacheBuster}.csv`
      );
      const snapshot = await uploadBytes(fileRef, file, {
        cacheControl: "public, max-age=31536000",
      });
      specialLog("file uploaded: ", snapshot);
      setIsLoading(false, "storeFile-success");

      return true;
    } catch (error) {
      addAlert(
        "File could not be uploaded, please try again later or contact us at support@fliqbook.in"
      );
      consoleError(error.code, error.message);
      setIsLoading(false, "storeFile-error");
    }
  };

  const getFile = async (month, fYear, type = "both") => {
    setIsLoading(true, "getFile");

    let b2b = null;
    let b2c = null;

    const cacheBuster = await getCacheBuster({ fYear, month });

    if (type !== "b2c") {
      try {
        const b2bFileRef = ref(
          storage,
          `${auth.currentUser.displayName}/${fYear}/${month}/MTR_B2B_${moment({
            M: month,
          }).format("MMMM")}${cacheBuster.b2b ?? ""}.csv`
        );
        const snapShot = await getBlob(b2bFileRef);
        b2b = await parseCsvFile(snapShot);
        b2b = b2b.filter((d) => d["Seller Gstin"]);
      } catch (error) {
        consoleWarn("b2b file error: ", error.code, error.message);
      }
    }

    if (type !== "b2b") {
      try {
        const b2cFileRef = ref(
          storage,
          `${auth.currentUser.displayName}/${fYear}/${month}/MTR_B2C_${moment({
            M: month,
          }).format("MMMM")}${cacheBuster.b2c ?? ""}.csv`
        );
        const snapShot = await getBlob(b2cFileRef);
        b2c = await parseCsvFile(snapShot);
        b2c = b2c.filter((d) => d["Seller Gstin"]);
      } catch (error) {
        consoleWarn("b2c file error: ", error.code, error.message);
      }
    }
    setIsLoading(false, "getFile");

    return { b2b, b2c };
  };

  const deleteFile = async (month, fYear, type) => {
    setIsLoading(true, "deleteFile");
    try {
      const cacheBuster = await getCacheBuster({ fYear, month });
      const fileRef = ref(
        storage,
        `${auth.currentUser.displayName
        }/${fYear}/${month}/MTR_${type.toUpperCase()}_${moment({
          M: month,
        }).format("MMMM")}${cacheBuster[type] ?? ""}.csv`
      );
      await deleteObject(fileRef);
      await storeAsinData({}, type, month, fYear);
      const newCacheBuster = Date.now();
      await storeCacheBuster(newCacheBuster, type, month, fYear);
      window.location.reload();
    } catch (error) {
      consoleWarn("file  delete error: ", error.code, error.message);
    }
    setIsLoading(false, "deleteFile");
  };

  const storeAsinData = async (fileData, type, month, fYear) => {
    const storeData =
      (await getData({
        documentId: "asin",
        fieldKey: fYear,
        subFieldKey: month,
      })) || {};

    storeData[type] = fileData;
    updateData({
      documentId: "asin",
      fieldKey: fYear,
      subFieldKey: month,
      newObject: storeData,
    });
  };

  const getAsinData = async ({ fYear, months = [] }) => {
    setIsLoading(true, "asin");
    const storeData = await getData({ documentId: "asin", fieldKey: fYear });

    if (storeData) {
      if (months.length) {
        const asinData = months.reduce((prev, curr) => {
          if (storeData[curr]) {
            setIsLoading(false, "asin");
            return { ...prev, [curr]: storeData[curr] };
          }
          setIsLoading(false, "asin");
          return prev;
        }, {});
        setIsLoading(false, "asin");
        return asinData;
      } else {
        setIsLoading(false, "asin");
        return storeData;
      }
    }

    return null;
  };

  const storeFinanceData = async ({ dataObject, month, fYear }) => {
    updateData({
      documentId: "finance",
      fieldKey: fYear,
      subFieldKey: month,
      newObject: dataObject,
    });
  };

  const getFinanceData = async ({ fYear, months = [] }) => {
    const storeData = await getData({ documentId: "finance", fieldKey: fYear });

    if (storeData) {
      if (months.length) {
        const financeData = months.reduce(
          (prev, curr) => {
            const output = { ...prev };
            if (storeData[curr]) {
              if (
                storeData[curr].hasOwnProperty("otherIncome") &&
                storeData[curr].otherIncome !== ""
              ) {
                output.otherIncome =
                  isNaN(prev.otherIncome) || prev.otherIncome === ""
                    ? +storeData[curr].otherIncome
                    : +prev.otherIncome + +storeData[curr].otherIncome;
              } else {
                output.missingMonths.otherIncome.push(curr);
              }

              if (
                storeData[curr].hasOwnProperty("interestExpense") &&
                storeData[curr].interestExpense !== ""
              ) {
                output.interestExpense =
                  isNaN(prev.interestExpense) || prev.interestExpense === ""
                    ? +storeData[curr].interestExpense
                    : +prev.interestExpense + +storeData[curr].interestExpense;
              } else {
                output.missingMonths.interestExpense.push(curr);
              }
            } else {
              output.missingMonths.otherIncome.push(curr);
              output.missingMonths.interestExpense.push(curr);
            }

            return output;
          },
          {
            otherIncome: "",
            interestExpense: "",
            missingMonths: { otherIncome: [], interestExpense: [] },
          }
        );

        return financeData;
      } else {
        return storeData;
      }
    }

    return null;
  };

  const getQuarterlyFiles = async (month, fYear) => {
    const month1 = await getFile(month, fYear);
    const month2 = await getFile(month - 1, fYear);
    const month3 = await getFile(month - 2, fYear);

    const quarterlyFile = { b2b: [], b2c: [], b2bMonths: [], b2cMonths: [] };
    if (month1?.b2b) {
      quarterlyFile.b2b = quarterlyFile.b2b.concat(month1.b2b);
      quarterlyFile.b2bMonths.push(month);
    }
    if (month1?.b2c) {
      quarterlyFile.b2c = quarterlyFile.b2c.concat(month1.b2c);
      quarterlyFile.b2cMonths.push(month);
    }
    if (month2?.b2c) {
      quarterlyFile.b2c = quarterlyFile.b2c.concat(month2.b2c);
      quarterlyFile.b2cMonths.push(month - 1);
    }
    if (month3?.b2c) {
      quarterlyFile.b2c = quarterlyFile.b2c.concat(month3.b2c);
      quarterlyFile.b2cMonths.push(month - 2);
    }

    return quarterlyFile;
  };

  const getHsnData = () => {
    return getData({ documentId: "hsnData" });
  };

  const storeHsnData = (data) => {
    return updateData({ documentId: "hsnData", newObject: data });
  };
  const getPurchaseData = (key) => {
    if (["inventory", "vendors", "expenses"].includes(key)) {
      return getData({ documentId: "purchases", fieldKey: key });
    }
  };

  const storeReconcileData = async (data, month, year) => {
    updateData({
      fieldKey: year,
      documentId: `reconciledData`,
      newObject: {
        ...data,
      },
      subFieldKey: month,
    });
  };

  const updateReconcileData = async (year, month, data) => {
    const reconcileData = await getReconcileData(year, month);
    reconcileData.missingOrders = reconcileData?.missingOrders?.map((item) => {
      const selectedItem = data.find(
        (selectedItem) => selectedItem["Order Id"] === item["Order Id"]
      );
      if (selectedItem) return selectedItem;
      return item;
    });

    updateData({
      fieldKey: year,
      documentId: `reconciledData`,
      newObject: { ...reconcileData },
      subFieldKey: month,
    });
  };

  const getReconcileData = async (year, month) => {
    const data = getData({
      documentId: "reconciledData",
      fieldKey: year,
      subFieldKey: month,
    });
    return data;
  };

  const storePurchaseData = (key, data) => {
    if (["inventory", "vendors", "expenses"].includes(key)) {
      return updateData({
        documentId: "purchases",
        fieldKey: key,
        newObject: data,
      });
    }
  };

  const createPartnerRequest = async (data) => {
    try {
      setIsLoading(true, "createPartnerRequest");
      const collectionRef = collection(db, "partner");
      const generalDocRef = await addDoc(collectionRef, {
        name: data.name ?? "",
        email: data.email,
        business: currentUser?.business ?? "",
        gstId: auth.currentUser.displayName,
        uid: auth.currentUser.uid,
        number: data.number,
        createdAt: serverTimestamp(),
        previous_sales: data.prevSale,
        connection_frequency: data.connectionFreq,
        other_market_places: data.marketPlaces,
        website_link: data.websiteLink,
      });

      updateUserData({
        partner: {
          docId: generalDocRef.id,
          isPending: true,
          isApproved: false,
        },
      });
      setIsLoading(false, "createPartnerRequest: success");

      return true;
    } catch (error) {
      consoleWarn(
        "createPartnerRequest error: ",
        error.code,
        error.message,
        error
      );
      setIsLoading(false, "createPartnerRequest: failure");

      return false;
    }
  };

  const createSupportRequest = async ({
    query = "",
    feature = "",
    bug = "",
  }) => {
    try {
      setIsLoading(true, "createSupportRequest");
      const collectionRef = collection(db, "support");
      const generalDocRef = await addDoc(collectionRef, {
        name: currentUser.owner?.name ?? "",
        email: currentUser.email,
        business: currentUser?.business ?? "",
        gstId: auth.currentUser.displayName,
        uid: auth.currentUser.uid,
        number: currentUser?.whatsapp_number,
        createdAt: serverTimestamp(),
        query,
        feature,
        bug,
      });

      const userDocRef = doc(db, auth.currentUser.uid, generalDocRef.id);
      await setDoc(
        userDocRef,
        { query, feature, bug, createdAt: serverTimestamp() },
        { merge: false }
      );
      setIsLoading(false, "createSupportRequest: success");

      return true;
    } catch (error) {
      consoleWarn("createSupportRequest error: ", error.code, error.message);
      setIsLoading(false, "createSupportRequest: failure");

      return false;
    }
  };

  const value = useMemo(() => ({
    analytics: {
      logData,
    },
    user: {
      updatePlan,
      deleteAccount,
      isGstIdExist,
      resetPassword,
      signin,
      signup,
      signout,
      getUserPicture,
      setUserPicture,
      currentUser,
      isEmailVerified,
      updateUserData,
      getAccessibleFYears,
    },
    sales: {
      deleteFile,
      getAsinData,
      getFile,
      getHsnData,
      getQuarterlyFiles,
      storeAsinData,
      storeFile,
      storeHsnData,
      storeReconcileData,
      getReconcileData,
      updateReconcileData,
    },
    purchase: {
      getPurchaseData,
      storePurchaseData,
    },
    finance: {
      getFinanceData,
      storeFinanceData,
    },
    support: {
      createSupportRequest,
    },
    partner: {
      createPartnerRequest,
    },
  }), [currentUser, isEmailVerified]);

  return (
    <ApiContext.Provider value={value}>
      {currentUser === undefined ? <Loader /> : children}
    </ApiContext.Provider>
  );
};
