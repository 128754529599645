export const B2bToHsn = (b2b) => {

  return b2b.reduce((output, currData) => {
    const reducedCurrentData = {
      'Hsn/sac': currData['Hsn/sac'],
      'igstTax': +currData['Igst Tax'],
      'sgstTax': +currData['Sgst Tax'],
      'Tax Exclusive Gross': +currData['Tax Exclusive Gross'],
      'taxRate': +currData['Igst Rate'] ? +currData['Igst Rate'] : +currData['Sgst Rate'] + +currData['Cgst Rate'],
      'Invoice Amount': +currData['Invoice Amount'],
      'Quantity': currData['Transaction Type'] === 'Shipment' ? currData.Quantity : currData['Transaction Type'] === 'Refund' ? - currData.Quantity : 0,
    };

    if (output[currData['Hsn/sac']]) {
      output[currData['Hsn/sac']].igstTax += reducedCurrentData.igstTax;
      output[currData['Hsn/sac']].sgstTax += reducedCurrentData.sgstTax;
      output[currData['Hsn/sac']]['Tax Exclusive Gross'] += reducedCurrentData['Tax Exclusive Gross'];
      output[currData['Hsn/sac']]['Invoice Amount'] += reducedCurrentData['Invoice Amount'];
      output[currData['Hsn/sac']].Quantity += reducedCurrentData.Quantity;
    } else {
      output[currData['Hsn/sac']] = reducedCurrentData;
    }
  }, {});
}

export const GetHsnDetail = (b2b, hsnDetail) => {
  b2b.map((currData) => {
    if (currData['Hsn/sac'].length && !hsnDetail[currData['Hsn/sac']]) {
        hsnDetail[currData['Hsn/sac']] = {
          description: '',
          uqc: 'PAC'
      }
    }
  });

  return hsnDetail;
}
