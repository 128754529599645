import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { consoleLog } from "@helper/logs";
import React from "react";
import Loader from "@molecules/loader/loader";

import { GlobalProvider } from "@context/global";
import { ApiProvider } from "@context/api";
import { PopupProvider } from "@context/popup";
import { SalesProvider } from "@context/sales";
import { PurchaseProvider } from "@context/purchase";

const LazyLogin = React.lazy(() => import(`@pages/login`));
const LazySignup = React.lazy(() => import(`@pages/signup`));
const LazyPartner = React.lazy(() => import(`@pages/partner`));
const LazyFinancials = React.lazy(() => import(`@pages/financials`));
const LazyTally = React.lazy(() => import(`@pages/tally`));
const LazyProfile = React.lazy(() => import(`@pages/profile`));
const LazyPaymentHistory = React.lazy(() => import(`@pages/paymentHistory`));
const LazyPurchases = React.lazy(() => import(`@pages/purchases`));
const LazySales = React.lazy(() => import(`@pages/sales`));
const LazySupport = React.lazy(() => import(`@pages/support`));

const LazySaleChart = React.lazy(() => import(`@organisms/sale-charts`));
const LazySaleSummary = React.lazy(() => import(`@organisms/sale-summary`));
const LazySaleTable = React.lazy(() => import(`@organisms/sale-table`));
const LazySaleOrderSettlement = React.lazy(() =>
  import(`@organisms/sale-order-settlement`)
);

const LazyLoadedPage = ({ component }) => {
  return <React.Suspense fallback={<Loader />}>{component}</React.Suspense>;
};

const LazyLoadedOrganism = ({ component }) => (
  <React.Suspense fallback={<></>}>{component}</React.Suspense>
);

export function App() {
  consoleLog("Rendering App");

    return (
      <BrowserRouter>
        <GlobalProvider>
          <PopupProvider>
            <ApiProvider>
              <Routes>
                <Route
                  element={
                    <SalesProvider>
                      <Outlet />
                    </SalesProvider>
                  }
                >
                  <Route
                    path="support"
                    element={<LazyLoadedPage component={<LazySupport />} />}
                  />

                  <Route
                    path="sales"
                    element={<LazyLoadedPage component={<LazySales />} />}
                  >
                    <Route
                      path="charts"
                      element={
                        <LazyLoadedOrganism component={<LazySaleChart />} />
                      }
                    />
                    <Route
                      path="table"
                      element={
                        <LazyLoadedOrganism component={<LazySaleTable />} />
                      }
                    />
                    <Route
                      path="summary"
                      element={
                        <LazyLoadedOrganism component={<LazySaleSummary />} />
                      }
                    />
                    <Route
                      path="order-settlement"
                      element={
                        <LazyLoadedOrganism
                          component={<LazySaleOrderSettlement />}
                        />
                      }
                    />
                    <Route
                      path=""
                      element={
                        <LazyLoadedOrganism component={<LazySaleChart />} />
                      }
                    />
                  </Route>
                  <Route
                    element={
                      <PurchaseProvider>
                        <Outlet />
                      </PurchaseProvider>
                    }
                  >
                    <Route
                      path="purchases"
                      element={<LazyLoadedPage component={<LazyPurchases />} />}
                    />
                    <Route
                      path="financials"
                      element={
                        <LazyLoadedPage component={<LazyFinancials />} />
                      }
                    />
                    <Route
                      path="tally"
                      element={<LazyLoadedPage component={<LazyTally />} />}
                    />
                  </Route>
                </Route>
                <Route
                  path="profile"
                  element={<LazyLoadedPage component={<LazyProfile />} />}
                />
                <Route
                  path="payment-history"
                  element={
                    <LazyLoadedPage component={<LazyPaymentHistory />} />
                  }
                />
                {/* <Route path="pricing" element={<Pricing2 />} /> */}
                <Route
                  path="signin"
                  element={<LazyLoadedPage component={<LazyLogin />} />}
                />
                <Route
                  path="signup"
                  element={<LazyLoadedPage component={<LazySignup />} />}
                />
                <Route
                  path="partner"
                  element={<LazyLoadedPage component={<LazyPartner />} />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/sales/charts" replace />}
                />
              </Routes>
            </ApiProvider>
          </PopupProvider>
        </GlobalProvider>
      </BrowserRouter>
    );
}
