import logo from "../components/atoms/images/logo.svg"

// https://razorpay.com/docs/payments/server-integration/nodejs/payment-gateway/build-integration/#checkout-options
export const RAZORPAY_OPTIONS = {
  "currency": "INR",
  "name": "Fliqbook Pvt Ltd",
  "image": logo,
  theme: {
    color: "#78C68C",
    hide_topbar: false
    // backdrop_color: "#fff"
  },
  modal: {
    confirm_close: true,
    ondismiss: () => console.log('User closed the payment modal')
  },
  remember_customer: true
};