import moment from 'moment';

export const currentYearMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const currentFYear = getFinancialYear(currentYear, currentMonth);

    return {
        currentFYear,
        currentYear,
        currentMonth,
        currentDate
    };
}

export const getFinancialYear = (yearString, monthString) => {
    const year = +yearString;
    const month = +monthString;

    return month > 2 ? `${year}-${year + 1 - 2000}` : `${year - 1}-${year - 2000}`;
}

export const getLastXFinancialYears = (x) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return [...Array(x)].map((d,i) => getFinancialYear(currentYear - i, currentMonth))
}

export const getYear = (fYear, month) => {

    return month > 2 ? +fYear.slice(0, 4) : +(fYear.slice(0, 2) + fYear.slice(5));
}

// not in use
export const getLastDateOfPreviousFYear = () => {
    const currentDate = new Date();
    const returnedYear = currentDate.getMonth() > 2 ? currentDate.getFullYear() : currentDate.getFullYear() - 1;

    return moment().set({ 'date': 31, 'month': 2, 'year': returnedYear }).format('DD/MM/YY')
}

export const convertMonthToIndex = (month) => {
    if (+month > 2) {
        return +month - 3;
    }

    return +month + 9
}

export const getLastDateOfMonth = (fYear, month) => {
    const selectedDate = new Date();
    selectedDate.month = month;
    selectedDate.year = getYear(fYear, month);

    return moment(selectedDate).endOf('month').format('DD/MM/YY')
}

export const convertDateFromCsv = (date) => {
    if (moment(date, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss');
    } else if (moment(date, 'DD-MM-YYYY H:mm', true).isValid()) {
        return moment(date, 'DD-MM-YYYY H:mm')
    } else if (moment(date, 'DD/MM/YY HH:mm:ss', true).isValid()) {
        return moment(date, 'DD/MM/YY HH:mm:ss')
    } else if (moment(date, 'DD/MM/YY H:mm', true).isValid()) {
        return moment(date, 'DD/MM/YY H:mm')
    } else {
        return moment(date);
    }
}

export const isSameYear = (date, year) => {
    const convertedDate = new Date(convertDateFromCsv(date));

    return convertedDate.getFullYear() === year;
}

export const isSameMonthRange = (startDate, endDate, currentMonth) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    return start.getMonth() === currentMonth || end.getMonth() === currentMonth || (start.getMonth() < currentMonth && currentMonth < end.getMonth())
}
export const isSameYearRange = (startDate, endDate, currentYear) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    return start.getFullYear() === currentYear || end.getFullYear() === currentYear || (start.getFullYear() < currentYear && currentYear < end.getFullYear())
}



export const isSameMonth = (date, month) => {
    const convertedDate = new Date(convertDateFromCsv(date));

    return convertedDate.getMonth() === month;
}

export const isDateAccessible = ({ m, y, yearList, quarterAccess }) => {
    const fYear = getFinancialYear(y, m);
    const { currentFYear, currentYear, currentMonth } = currentYearMonth();
    const response = {
        valid: false,
        y: currentMonth ? currentYear : currentYear - 1, // fallback if response is not valid
        m: currentMonth ? currentMonth - 1 : 11, // fallback if response is not valid
        errorMessage: null
    }

    if (!yearList.includes(fYear)) {
        response.errorMessage = '⚠️ Selected financial year is not available!';
    } else if (fYear === currentFYear && (y > currentYear || (y === currentYear && m >= currentMonth))) {
        response.errorMessage = '⚠️ Selecting future month is not possible!';
    } else if (fYear === currentFYear && (quarterAccess === 2 && (((!currentMonth || currentMonth > 9) && m < 6) || (currentMonth && currentMonth < 3 && y < currentYear && m < 9)))) {
        response.errorMessage = 'Upgrade your plan to access this month!';
    } else if (fYear !== currentFYear && (quarterAccess === 2 && m > 2)) {
        response.errorMessage = 'Upgrade your plan to access this month!';
    } else {
        response.valid = true;
    }

    return response;
}

export const getDateFromSecond = (timestampInSecond) => moment.unix(timestampInSecond)

export const isFutureDate = (timestampInSecond) => {
    const startDate = moment.unix(timestampInSecond);

    return moment().diff(startDate, "days") < 0;
}

export const isPastInvoice = (invoiceDate, { fYear, month }) => {
    const endDate = moment([getYear(fYear, month), month + 1, 1])

    return moment(moment(invoiceDate, 'YYYY-MM-DD')).diff(endDate, "days") < 0;
}

export const isFutureMonth = (data, baseData) => {
    const startDate = moment([getYear(data.fYear, data.month), month, 1])
    const endDate = moment([getYear(baseData.fYear, baseData.month), month, 1])

    return moment(startDate).diff(endDate, "months") > 0;
}

export const isFutureFYear = (fYear, baseFYear) => {
    return getYear(fYear, 0) > getYear(baseFYear, 0);
}

export const dayPassed = (timestampInSecond) => {
    const startDate = moment.unix(timestampInSecond);

    return moment().diff(startDate, "days");
}

export const daysLeft = (timestampInSecond, validityInDays) => {
    const daysPassed = dayPassed(timestampInSecond);
    const daysLeft = daysPassed > 0 ? validityInDays - daysPassed : validityInDays;

    return daysLeft;
}

export const addDays = (timestampInSecond, days) => {
    return moment.unix(timestampInSecond).add(days + 1, 'days');
}
