import moment from "moment";
import React from "react";
import fliq from '@atoms/images/fliqbook.png'


const InvoiceTemplate = ({ invoiceData, user }) => {
  const stateTax = invoiceData?.amount
    ? (+invoiceData.amount * 0.09).toFixed(2)
    : 0;
  const integratedTax = (stateTax * 2).toFixed(2);
  return (
    <>
      <div className="flex justify-between items-center mt-3 mb-3  ">
        <div>
          <img src={fliq} className="" />
        </div>
        <div>
          <div>
            <h1 className="mb-4 text-4xl font-extrabold leading-none text-gray-900 md:text-5xl lg:text-3xl dark:text-white">
              Tax Invoice
            </h1>
          </div>
          <h1 className="mb-4 text-3xl  leading-none text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
            (Original for Recipient)
          </h1>
        </div>
      </div>

      <div className="flex justify-between items-center mt-3 mb-3 ">
        <div className="w-full md:w-2/5   md:text-left">
          <span>
            H/1/15 Arjun Apt., Nr. Shanti Apt., Nr. Pragatinagar Road,
            Naranpura, Ahmedabad - 380013 Gujarat
          </span>
        </div>
        <div className="w-full md:w-2/5   md:text-right">
          <table>
            <tr>
              <td>Invoice Date:</td>
              <td>{invoiceData?.subscriptionDate?.seconds ? moment
              .unix(invoiceData?.subscriptionDate?.seconds)
              .format("DD /MM/YYYY") : "-"}</td>
            </tr>
            <tr>
              <td>Invoice Number:</td>
              <td>FLIQ_{invoiceData?.invoiceId}</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="pan-more-detail-section">
        <span>GST Tax Registration No: 24BXLPD4330H1ZS</span>
      </div>
      <div className="mt-3 mb-3">
        <hr />
      </div>
      <div className="mt-3 mb-3">
        <b>Bill to</b>
        <div>
          <span>Name: {user?.business}</span>
        </div>
        <div>
          <span>Address: {user?.office}</span>
        </div>

        <div>
          <span>GSTIN: {user?.gst_no}</span>
        </div>
      </div>

      <table className="border-collapse border border-slate-500 ...">
        <tbody>
          <tr>
            <th className="border border-slate-600 ... p-2" rowSpan="2">
              SI No
            </th>
            <th className="border border-slate-600 ... p-2" rowSpan="2">
              Category of Service
            </th>
            <th className="border border-slate-600 ... p-2" rowSpan="2">
              Description of Service
            </th>
            <th className="border border-slate-600 ... p-2" rowSpan="2">
              Tax Rate
            </th>
            <th className="border border-slate-600 ... p-2" colSpan="3">
              Amount
            </th>
          </tr>
          <tr></tr>

          <tr>
            <td className="border border-slate-600 ... p-2">1</td>
            <td className="border border-slate-600 ... p-2">998222</td>
            <td className="border border-slate-600 ... p-2">
              Accounting & Bookkeeping Subscription{" "}
            </td>
            <td className="border border-slate-600 ... p-2"></td>
            <td className="border border-slate-600 ... p-2">
              {" "}
              INR {invoiceData?.amount ?? "xx"}
            </td>
          </tr>
          {/* // 24BXLPD4330H1ZS */}
          {user?.gst_no.substring(0, 2) === "24" ? (
            <>
              <tr>
                <td className="border border-slate-600 ... p-2"></td>
                <td className="border border-slate-600 ... p-2"> </td>
                <td className="border border-slate-600 ... p-2">SGST</td>
                <td className="border border-slate-600 ... p-2">9.00% </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {stateTax !== "NaN" ? stateTax : 0}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2"></td>
                <td className="border border-slate-600 ... p-2"> </td>
                <td className="border border-slate-600 ... p-2">CGST</td>
                <td className="border border-slate-600 ... p-2">9.00% </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {stateTax !== "NaN" ? stateTax : 0}
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td className="border border-slate-600 ... p-2"></td>
              <td className="border border-slate-600 ... p-2"> </td>
              <td className="border border-slate-600 ... p-2">IGST</td>
              <td className="border border-slate-600 ... p-2">18.00% </td>
              <td className="border border-slate-600 ... p-2">
                {" "}
                INR {integratedTax}
              </td>
            </tr>
          )}

          <tr>
            <td className="border border-slate-600 ...  p-2"></td>
            <td className="border border-slate-600 ...  p-2"></td>
            <td className="border border-slate-600 ...  p-2">Total:</td>
            <td className="border border-slate-600 ...  p-2"></td>
            <td className="border border-slate-600 ...  p-2">
              {" "}
              INR {invoiceData?.amount ?? "xx"}
            </td>
          </tr>
        </tbody>
      </table>
      {/* {props?.invoiceData?.SGST && props?.invoiceData?.CGST ? (
        <div className="justify-center items-center flex mt-3 mb-3">
          <table className="border-collapse border border-slate-500 ...">
            <tbody>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  <b>Subtotal of fees amount </b>
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.amount}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  Subtotal for SGST{" "}
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.SGST}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  Subtotal for CGST{" "}
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.CGST}
                </td>
              </tr>
             
              <tr>
                <td className="border border-slate-600 ... p-2">
                  <b> Total Invoice amount </b>
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.Total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="justify-center items-center flex mt-3 mb-3">
          <table className="border-collapse border border-slate-500 ...">
            <tbody>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  <b> Subtotal of fees amount </b>
                </td>
                <td> INR {props?.invoiceData?.amount}</td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  Subtotal for IGST{" "}
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.IGST}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  <b>Subtotal of GST amount </b>
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.IGST}
                </td>
              </tr>
              <tr>
                <td className="border border-slate-600 ... p-2">
                  <b> Total Invoice amount </b>
                </td>
                <td className="border border-slate-600 ... p-2">
                  {" "}
                  INR {props?.invoiceData?.Total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )} */}

      <div className="mt-3 mb-10 pb-10">
        Whether tax is payable under reverse charge - No
      </div>

      {/* <div className="mt-3 mb-3">
        <b>Details of Fees to the above Tax Invoice</b>
      </div> */}
    </>
  );
};

export default InvoiceTemplate;
