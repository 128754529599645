import moment from "moment";

export const initialState = {
  currentStockData: () => {},
  incomeStatement: () => {},
  tallyObject: () => {}
};

export const PurchaseReducerActionType = {
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  UPDATE_EXPENSE: 'UPDATE_EXPENSE'

}

export const purchaseReducer = (state, action) => {
  const { type, payload } = action;

  if (payload.storePurchaseData) {
    if (payload.inventory) {
      payload.storePurchaseData('inventory', payload.inventory);
    }
  
    if (payload.vendors) {
      payload.storePurchaseData('vendors', payload.vendors);
    }
  
    if (payload.expenses) {
      payload.storePurchaseData('expenses', payload.expenses.sort((a, b) => moment(a.invoiceDate).diff(moment(b.invoiceDate))));
    }
  }

  switch (type) {
    case PurchaseReducerActionType.UPDATE_PRODUCT: {
      return {
        ...state,
        inventory: payload.inventory,
      }
    }

    case PurchaseReducerActionType.UPDATE_VENDOR: {
      return {
        ...state,
        vendors: payload.vendors,

      }
    }

    case PurchaseReducerActionType.UPDATE_EXPENSE: {
      return {
        ...state,
        expenses: payload.expenses.sort((a, b) => moment(a.invoiceDate).diff(moment(b.invoiceDate))),

      }
    }

    default:
      return state
  }

}

