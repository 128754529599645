import { Timestamp } from "firebase/firestore";
import { addDays, daysLeft, isFutureDate } from "./time";
import { ORDER_STATUS } from "../constants/pricing";

export const addOrder = (orderHistory, newOrder) => {
  const subscriptionDate = Timestamp.now();
  newOrder.subscriptionDate = subscriptionDate;
  const lastSuccessfullOrder = Array.isArray(orderHistory) && orderHistory?.find(order => (!order.status || order.status === ORDER_STATUS.SUCCESS))
  const remainingDays = daysLeft(lastSuccessfullOrder.startDate.seconds, lastSuccessfullOrder.validityInDays);

  if (remainingDays > 0) {
    newOrder.startDate = Timestamp.fromDate(addDays(subscriptionDate.seconds, remainingDays).toDate())
  } else {
    newOrder.startDate = subscriptionDate;
  }

  orderHistory.unshift(newOrder);

  return [...orderHistory]
}

export const getActiveOrders = (orderHistory) => {
  return orderHistory.reduce((activeOrderList, order) => {
    if (!order.status || order.status === ORDER_STATUS.SUCCESS) {
      const remainindays = daysLeft(order.startDate.seconds, order.validityInDays);

      if (daysLeft(order.startDate.seconds, order.validityInDays) > 0) {
        activeOrderList.push({ ...order, daysLeft: remainindays })
      }
    }

    return activeOrderList;
  }, []);
}

export const getCurrentPlan = (orderHistory) => {
  return orderHistory.find(order => {
    return (
      (!order.status || order.status === ORDER_STATUS.SUCCESS) &&
      daysLeft(order.startDate.seconds, order.validityInDays) > 0 && !isFutureDate(order.startDate.seconds)
    )
  });
}